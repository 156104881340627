<template>
	<div id='publ-home'>
		<h2 v-if="ResearchTargetTitle" style="margin-bottom:2rem">{{ResearchTargetTitle}}</h2>
		<slider-comp
			:itemList = "bookList"
			:sliderTitle = "t ? t.books : '...'"
			:sliderCap = "t ? t.new : '...'"
			:searchParams = "searchParams"
			publsType = "pBook"
			:publsId = "`books`"
			:itemsCnt = "itemCnt"
			:showCover = true
			:ruVersion = "ruVersion"
			:config="config"
		></slider-comp> 
		<slider-comp
			:itemList = "articleList"
			:sliderTitle = "t ? t.articles : '...'"
			:sliderCap = "t ? t.new : '...'"
			:searchParams = "searchParams"
			publsType = "pArticle"
			publsId = "articles"
			:itemsCnt = "itemCnt"
			:showCover = false
			:ruVersion = "ruVersion"
			:config="config"
		></slider-comp>
		<slider-comp
			:itemList = "chapterList"
			:sliderTitle = "t ? t.chapters : '...'"
			:sliderCap = "t ? t.new : '...'"
			:searchParams = "searchParams"
			publsType = "pChapter"
			publsId = "chapters"
			:itemsCnt = "itemCnt"
			:showCover = false
			:ruVersion = "ruVersion"
			:config="config"
		></slider-comp>
		<slider-comp
			:itemList = "preprintList"
			:sliderTitle = "t ? t.preprints : '...'"
			:sliderCap = "t ? t.new : '...'"
			:searchParams = "searchParams"
			publsType = "pPreprint"
			publsId = "preprints"
			:itemsCnt = "itemCnt"
			:showCover = false
			:ruVersion = "ruVersion"
			:config="config"
		></slider-comp>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import SliderComp from './components/sliderComp.vue';

	export default {
		name: 'publHome',
		components: {
			'slider-comp': SliderComp,
		},
		metaInfo() {
			return {
				lang: this.ruVersion ? 'ru' : 'en',
			}
		},
		data: () => ({
			showCover: false,
			itemCnt: 4,
			searchParams: '',
			ResearchTargetTitle: '',
		}),
		computed: mapState ({
			bookList: state => state.bookList,
			articleList: state => state.articleList,
			chapterList: state => state.chapterList,
			preprintList: state => state.preprintList,
			t: state => state.templateT,
			ruVersion: state => state.templateRuVersion,
			researchTarget: state => state.templateResearchTarget,
			config: state => state.config,
		}),
		serverPrefetch () {
			this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
			return this.getPublsList(this.config.serverBaseURL);
		},
		mounted () {
			if (!this.bookList) {
				// console.log(`>>> Publication.mounted : ${Date.now().toLocaleString('ru-RU')}`)
				this.getPublsList(this.config.clientBaseURL);
			}
			// SecondaryMenu selected
			this.$store.dispatch('aChangeSelectSecongaryMenu', 0);

			if (this.$router && this.$router.currentRoute && this.$router.currentRoute.path.split('/').includes('rubric')) {
				const id = Number(this.$router.currentRoute.params.id.replace(/\D+/g,""));
				this.ResearchTargetTitle = this.getResearchTargetTitle(id, this.researchTarget);
			}
		},
		methods: {

			getResearchTargetTitle (id, rt) {
				if (id && rt) {
					for (const el of rt) {
						if (el.id == id ) {
							return el.title;
						}
						if (el.children && el.children.length > 0) {
							const res = this.getResearchTargetTitle(id, el.children)
							if (res) {
								return res;
							}
						}
					}
				};
				return null;
			},
			getPublsList (baseURL) {
				let filterParams = ` acceptLanguage: ${this.ruVersion ? 'ru' : 'en'}`;
				this.ResearchTargetTitle = '';

				// Тематики/Рубрики
				if (this.$router && this.$router.currentRoute && this.$router.currentRoute.path.split('/').includes('rubric')) {
					const id = Number(this.$router.currentRoute.params.id.replace(/\D+/g,""));
					if (Number.isInteger(id)) {
						filterParams += ` researchTargets: ${id} `;
						this.searchParams = `?research_target=${id}`;
					}
				}
				const params = {
					type: 'ANY',
					fullTextPublicEnabled: true,
					filterParams,
					paginationParams: {
						publsCount: 20,
						pageId:1,
						publsSort: 'CTIME_DESC',
					},
				}
				// console.log(`>>> getPublsList params: ${JSON.stringify(params)}`)
				return this.$store.dispatch('aLoadPublsList', {params, baseURL}); // 'CREATED_AT_DESC' 'NEW_DESC'
			}
		}
	};
</script>
