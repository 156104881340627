<template>

<div class="drop-between-zone"
     :class="{ 'active': nodeDragOver }"
     @drop.prevent.stop="drop"
     @dragover.prevent="dragover"
     @dragenter.prevent.stop="nodeDragOver = true"
     @dragleave.prevent.stop="nodeDragOver = false">
</div>

</template>

<script>
// import EventBus from './EventBus';

export default {
  name: 'drop-between-zone',
  data() {
    return {
      nodeDragOver: false,
    }
  },
  methods: {
    draggingEnded() {
      this.draggedNode = null
    },
    dragover(ev) {
      ev.dataTransfer.dropEffect = 'move'
    },
    drop() {
      this.$emit('nodeDrop')
    }
  }
}

</script>
