<template>
	<div v-if="itemList && itemList.length" class="pubs-slider pubs-card vis-bg vis-border vis-color">
		<a :id="publsId"></a>
		<div class="pubs-card__header">
			<div :data-sup="sliderCap" class="pubs-card__caption">
				<a v-if="titleAsLink" class="link_inherit link_no-underline" :href="`${getPrefixURLLang()}${this.publsId}/${searchParams}`">{{sliderTitle}}</a>
				<span v-else >{{sliderTitle}}</span>
			</div>
		</div>
		<div class="pubs-slider__list column owl-carousel owl-loaded owl-drag">
			<carousel v-if="isClientReady"
				:items="itemsCnt"
				:nav=true
				:slideBy="itemsCnt"
				:loop="itemList.length > itemsCnt"
				:margin=5
				:rewind=false
				:dots=true
				:mouseDrag=true
				:autoWidth=false
				:mergeFit=true
				:stagePadding=2
				:navText="['','']"
				:responsive="responsiveParameters"
			>
				<div v-for='itm in (itemList && itemList.length)  ? itemList : []' :key='itm.id' class="pubs-slider__wrapper">
					<div class="column__item">
						<a v-if="showCover" class="link link_no-underline" :href="`${getPrefixURLLang()}view/${itm.id}`">
							<img class="pubs-item__img __iwc" 
							:src="`${itm.documents.COVER && itm.documents.COVER.href ? itm.documents.COVER.href+`:r135x200${itm.documents.COVER.ext ? '.'+itm.documents.COVER.ext : ''}` : '/f/src/pubs/default.png'}`" 
							:alt="`${itm.title}`"
							:srcset="`${itm.documents.COVER && itm.documents.COVER.href ? itm.documents.COVER.href+`:r270x400${itm.documents.COVER.ext ? '.'+itm.documents.COVER.ext : ''} 2x` : '/f/src/pubs/default.png'}`"
							>
						</a>

						<div class="pubs-item__title">
							<a class="link" :href="	`${getPrefixURLLang()}view/${itm.id}`">{{itm.title}}</a>
						</div>

						<div class="pubs-item__info">
							<span v-for='(author, ind ) in itm && itm.authorsByType && itm.authorsByType.author ? itm.authorsByType.author : []' :key='author.id' >
								<template v-if="ind < 3">
									<span v-if="ind > 0" class="nowrap">, </span>
									<a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${getHseURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
									<span v-else class="nowrap pubs-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
								</template>
								<span v-else-if="ind == 3"> {{ruVersion ? 'и др.' : 'et al.'}}</span>
							</span>{{itm && itm.authorsByType && itm.authorsByType.author && itm.authorsByType.author.length && itm.description.short.publ ? ', ' : ''}}{{itm && itm.description && itm.description.short.publ ? itm.description.short.publ : ''}}.
						</div>

						<div class="pubs-item__desc is-truncated" style="overflow-wrap: break-word;">
							{{cutSentens(langChoice(itm.annotation))}}
						</div>
						<div class="pubs-item__date">{{ruVersion ? 'Добавлено' : 'Added'}}: {{dateFormatter(itm.createdAt, ruVersion)}}</div>
					</div>
				</div>
			</carousel>
			<template v-if="!isClientReady">
				<div v-for='itm in (itemList && itemList.length)  ? itemList : []' :key='itm.id' class="pubs-slider__wrapper">
					<div class="column__item">
						<a v-if="showCover" class="link link_no-underline" :href="`${getPrefixURLLang()}view/${itm.id}`">
							<img class="pubs-item__img __iwc" 
							:src="`${itm.documents.COVER && itm.documents.COVER.href ? itm.documents.COVER.href+`:r135x200${itm.documents.COVER.ext ? '.'+itm.documents.COVER.ext : ''}` : '/f/src/pubs/default.png'}`" 
							:alt="`${itm.title}`"
							:srcset="`${itm.documents.COVER && itm.documents.COVER.href ? itm.documents.COVER.href+`:r270x400${itm.documents.COVER.ext ? '.'+itm.documents.COVER.ext : ''} 2x` : '/f/src/pubs/default.png'}`"
							>
						</a>

						<div class="pubs-item__title">
							<a class="link" :href="	`${getPrefixURLLang()}view/${itm.id}`">{{itm.title}}</a>
						</div>

						<div class="pubs-item__info">
							<span v-for='(author, ind ) in itm && itm.authorsByType && itm.authorsByType.author ? itm.authorsByType.author : []' :key='author.id'>
								<template v-if="ind < 3">
									<span v-if="ind > 0" class="nowrap">, </span>
									<a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${getHseURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
									<span v-else class="nowrap pubs-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
								</template>
								<span v-else-if="ind == 3"> {{ruVersion ? 'и др.' : 'et al.'}}</span>
							</span>{{itm && itm.authorsByType && itm.authorsByType.author && itm.authorsByType.author.length ? ', ' : ''}}{{itm && itm.description && itm.description.short.publ ? itm.description.short.publ : ''}}
						</div>

						<div class="pubs-item__desc is-truncated" style="overflow-wrap: break-word;">
							{{cutSentens(langChoice(itm.annotation))}}
						</div>
						<div class="pubs-item__date">{{ruVersion ? 'Добавлено' : 'Added'}}: {{dateFormatter(itm.createdAt, ruVersion)}}</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { mixUtils } from '../mixins/mixUtils.js';
    export default {
		name: 'PublsSlider',
        props: {
            itemList: {
                type: Array,
                required: true,
                default: () => [],
            },
            sliderTitle: {
                type: String,
                required: true,
                default: 'Slider'
			},
            sliderCap: {
                type: String,
                required: false,
                default: ''
			},
            searchParams: {
                type: String,
                required: false,
                default: ''
			},
			publsId: {
				type: String,
				required: true,
				default: "view"
			},
            itemsCnt: {
                type: Number,
                required: false,
                default: 2
			},
			showCover: {
                type: Boolean,
                required: false,
                default: false
			},
			titleAsLink: {
                type: Boolean,
                required: false,
                default: true
			},
            publsType: {
                type: ('pBook'|'pChapter'|'pArticle'|'pPreprint'),
                reuired: true,
            },
            ruVersion: {
                type: Boolean,
                reuired: true,
                default: true,
			},
			config: {
                type: Object,
                required: false,
                default: () => {},
			},
		},
		mixins: [mixUtils,],
		data: () => ({
			isClientReady: false,
			responsiveParameters: {},
		}),
        mounted () {
			if (typeof window !==  'undefined') {
				this.responsiveParameters = this.itemsCnt >= 4
					? {0 : { items: 1 }, 414 : { items: 1 }, 767 : { items: 2 }, 995 : { items: 2 }, 1100 : { items: 3 }, 1300 : { items: 4 }}
					: {0 : { items: 1 }, 414 : { items: 1 }, 767 : { items: 1 }, 995 : { items: 2 }, 1100 : { items: 2 }, 1300 : { items: 2 }}
				Vue.component('carousel', () => import('vue-owl-carousel'));
				this.isClientReady = true;
			}
		},
    };
</script>