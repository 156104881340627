<template>
	<div id="publ-home">
		<page-comp
			:publs = "book"
			:publs-type = "publsType"
			:ru-version = "ruVersion"
			:t = "t"
			:config="config"
		></page-comp>

		<slider-comp
			:item-list = "related"
			:slider-title = "t ? t.similarPublications : '...'"
			:publs-type = "publsType"
			:publs-id = "publsId"
			:items-cnt = "itemsCnt"
			:show-cover = "showCover"
			:ru-version = "ruVersion"
			:config="config"
		></slider-comp>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import PageComp from './components/pageComp.vue';
	import SliderComp from './components/sliderComp.vue';
	import { mixUtils } from './mixins/mixUtils.js';

	export default {
		name: 'Book',
		components: {
			'page-comp': PageComp,
			'slider-comp': SliderComp,
		},
		props: {
			id: {
				type: String,
				required: false,
				default: '0',
			},
		},
		mixins: [mixUtils,],
		metaInfo() {
			return {
				title: this.book && this.book.title ? this.book.title :'',
				meta: this.book && this.book.metaTegs ? this.book.metaTegs.filter(el => el.id!=="link_image_src" ) : [],
				link: this.book && this.book.metaTegs ? this.book.metaTegs.filter(el => el.id==="link_image_src" ) : [],
				lang: this.ruVersion ? 'ru' : 'en',
			}
		},
		data: () => ({
			currId: 0,
			publsType: "pBook",
			publsId: "books",
			itemsCnt: 4,
			showCover: false,
		}),
		computed: mapState ({
			book: state => state.book,
			config: state => state.config,
			related: state => (state.book && state.book.related) ? state.book.related : [],
			ruVersion: state => state.templateRuVersion,
			t: state => state.templateT,
		}),
		methods: {
			/**
			 * Загрузка основных данных
			 */
			getBook(baseURL) {
				return this.$store.dispatch('aLoadBook', {params:{"id": this.currId}, baseURL});
			},
		},

		/**
		 * Сервер. Первичное гидрирование
		 */
		serverPrefetch () {
			this.currId = this.id // $route.params.id
			this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
			return this.getBook(this.config.serverBaseURL);
		},
		/**
		 * Слиент. Доп. загрузка
		 */
		mounted () {
			this.currId = this.id
			if (!this.book) {
				this.getBook(this.config.clientBaseURL);
			}
		},

	};
</script>
