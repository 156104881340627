<template>
	<div class="sv-control">
				<div class="sv-control__block">
					<ul class="sv-control__list sv-size">
						<li v-for="sz in sv.svSize ? sv.svSize.options : []" :key="sz.id" :data-type="sz.dataType" :data-value="sz.dataValue" :class="sz.class + (sz.active ? ' active': '')"  @click="onHandleSV(sz)">{{sz.title}}</li>
					</ul>
				</div>
				<div class="sv-control__block">
					<ul class="sv-control__list sv-spacing">
						<li v-for="sp in sv.svSpacing ? sv.svSpacing.options : []" :key="sp.id" :data-type="sp.dataType" :data-value="sp.dataValue" :class="sp.class + (sp.active ? ' active': '')"  @click="onHandleSV(sp)">{{sp.title}}</li>
					</ul>
				</div>
				<div class="sv-control__block">
					<ul class="sv-control__list sv-contrast">
						<li v-for="sc in sv.svContrast ? sv.svContrast.options : []" :key="sc.id" :data-type="sc.dataType" :data-value="sc.dataValue" :class="sc.class + (sc.active ? ' active': '')"  @click="onHandleSV(sc)">{{sc.title}}</li>
					</ul>
				</div>
				<div class="sv-control__block">
					<ul class="sv-control__list sv-image">
						<li v-for="si in sv.svImage ? sv.svImage.options : []" :key="si.id" :data-type="si.dataType" :data-value="si.dataValue" :class="si.class + (si.active ? ' active': '')"  @click="onHandleSV(si)">{{si.title}}</li>
					</ul>
				</div>
				<div class="sv-control__block">
					<div class="sv-off js-sv-off" @click.stop=onNormalizeMode>Обычная версия сайта</div>
				</div>
			</div>
</template>

<script>
	import Vue from 'vue'
    export default {
		name: 'PublsSVControl',
        props: {
            sv: {
                type: Object,
                required: false,
                default: () => {},
            },
            ruVersion: {
                type: Boolean,
                required: false,
                default: true
            },
			config: {
                type: Object,
                required: false,
                default: () => {},
			},

			onHandleSV: {
				type: Function,
				required: true,
			},
			onNormalizeMode: {
				type: Function,
				required: true,
			},
		},
    };
</script>