<template>
    <div class="search-result-list">
        <div class="b-person-data-pubs">
			<h2 v-if="showPubsHeader" class="pubs-list-h2">{{ruVersion ? 'Публикации':'Publications'}}<sup v-if='showTotalPubls'>{{totalFoundPubls}}</sup></h2>
            <div v-if="admComment" class="b-person-data__inner publications-header">
				<p class="first_child last_child text" v-html="admComment"></p>
			</div>
			<div v-if="showSortBtn" class="column__item column__item--2_3">
				<div class="pubs-search__category">{{ruVersion ? 'Сортировка' : 'Sort'}}:</div>
				<div v-for='btn in sortBtns' :key='btn.id' class="pubs-search__sort">
                    <input
                        type="radio"
                        class="pubs-search__tag--sort"
                        :id="`pubs-search__sort_${config && config.pubsUnit ? config.pubsUnit : ''}_${btn.id}`"
                        :value="`${btn.value}`"
                        name="search-result"
                        @click="sortBtnClick(btn)"
                        v-model="sortValue"
                    >
                    <label :for="`pubs-search__sort_${config && config.pubsUnit ? config.pubsUnit : ''}_${btn.id}`" :class="`pubs-search__tag--sort ${btn.id === 0 ? sortRangeBtn_0 : sortRangeBtn_1}`">{{ ruVersion ? btn.ruLabel : btn.enLabel }}</label>
				</div>
			</div>
		</div>
		<div class="g-ul g-list g-list_closer publications">
            <template v-if="pubsPersonGroupType === 0">
                <search-result-list-item
                    v-if="!showVertelka"
                    :itemList="itemList"
                    :t="t"
                    :ruVersion="ruVersion"
                    :showCover="showCover"
                    :config="config"
                ></search-result-list-item>
            </template>
            <template v-if="pubsPersonGroupType === 1">
                <div v-if="itemList.book" class="pubs-list-group-book">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.book.header.ru : itemList.book.header.en}}<sup>{{itemList.book.items.length}}</sup></h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.book.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
                <div v-if="itemList.art_chap" class="pubs-list-group-art_chap">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.art_chap.header.ru : itemList.art_chap.header.en}}<sup>{{itemList.art_chap.items.length}}</sup></h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.art_chap.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
                <div v-if="itemList.edited" class="pubs-list-group-edited">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.edited.header.ru : itemList.edited.header.en}}<sup>{{itemList.edited.items.length}}</sup></h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.edited.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
                <div v-if="itemList.translate" class="pubs-list-group-translate">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.translate.header.ru : itemList.translate.header.en}}<sup>{{itemList.translate.items.length}}</sup></h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.translate.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
                <div v-if="itemList.preprint" class="pubs-list-group-preprint">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.preprint.header.ru : itemList.preprint.header.en}}<sup>{{itemList.preprint.items.length}}</sup></h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.preprint.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
                <div v-if="itemList.no_group" class="pubs-list-group-no_group">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.no_group.header.ru : itemList.no_group.header.en}}<sup>{{itemList.no_group.items.length}}</sup></h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.preprint.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
            </template>
            <template v-if="pubsPersonGroupType === 2">
                <div v-if="itemList.year" class="pubs-list-group-years">
                    <!-- <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.year.header.ru : itemList.year.header.en}}</h3> -->
                    <div v-for='itYear in Object.keys(itemList.year.items).sort((v1,v2)=> (pubsPersonSort === 0 ? v1>v2 : v1<v2) ? 1 : -1)' :key='itYear' class="pubs-list-group-year">
                        <h3 class="pubs-list-subgroup-h3">{{itYear}}<sup>{{itemList.year.items[itYear].length}}</sup></h3>
                        <search-result-list-item
                            v-if="!showVertelka"
                            :itemList="itemList.year.items[itYear]"
                            :t="t"
                            :ruVersion="ruVersion"
                            :showCover="showCover"
                            :config="config"
                        ></search-result-list-item>
                    </div>
                </div>
                <div v-else class="pubs-list-group-no_group">
                    <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList.no_group.header.ru : itemList.no_group.header.en}}</h3>
                    <search-result-list-item
                        v-if="!showVertelka"
                        :itemList="itemList.no_group.items"
                        :t="t"
                        :ruVersion="ruVersion"
                        :showCover="showCover"
                        :config="config"
                    ></search-result-list-item>
                </div>
            </template>
            <template v-if="pubsPersonGroupType === 3">
                <div v-for='pos in Object.keys(itemList).sort((v1,v2)=> (v1>v2) ? 1 : -1)' :key='pos' class="pubs-list-custom-group">
                    <template v-if='itemList[pos].items.length > 0'>
                        <h3 class="pubs-list-subgroup-h3">{{ruVersion ? itemList[pos].header.ru : itemList[pos].header.en}}<sup>{{itemList[pos].items.length}}</sup></h3>
                        <div class="pubs-list-group">
                            <search-result-list-item
                                v-if="!showVertelka"
                                :itemList="itemList[pos].items"
                                :t="t"
                                :ruVersion="ruVersion"
                                :showCover="showCover"
                                :config="config"
                            ></search-result-list-item>
                        </div>
                    </template>
                </div>
            </template>
		</div>
    </div>
</template>

<script>
    import { mixUtils } from '../mixins/mixUtils.js';
    import SearchResultListItemComp from "./searchResultListItemComp.vue";
    export default {
        name: 'SearchResultList',
        components: {
			"search-result-list-item": SearchResultListItemComp,
		},
        props: {
            itemList: {
                type: Array,
                required: false,
                default: () => [],
            },
            totalFoundPubls: {
                type: Number,
                required: false,
                default: 1,
            },
            pubsPersonGroupType: {
                type: Number,
                required: false,
                default: 0,
            },
            pubsPersonSort: {
                type: Number,
                required: false,
                default: 0,
                // (0) без группировки - показывается просто список
                // (1) группировка на фиксированные разделы: Книги, Статьи и главы в книгах, Под редакцией, Переводы, Препринты
                // (2) группировка по году
                // (3) группировка по кастомным разделам (raw_pubs_group)
            },
            t: {
				type: Object,
				required: false,
				default: () => {},
			},
            ruVersion: {
                type: Boolean,
                required: true,
                default: true,
            },
            sortBtns: {
                type: Array,
                required: false,
                default: () => [],
            },
            sortValue: {
				type: String,
				required: false,
				default: 'TITLE',
            },
            admComment: {
				type: String,
				required: false,
				default: '',
            },
            showPubsHeader: {
                type: Boolean,
                required: false,
                default: true,
			},
            showSortBtn: {
                type: Boolean,
                required: false,
                default: true,
			},
            showCover: {
                type: Boolean,
                required: false,
                default: false
			},
            showTotalPubls: {
                type: Boolean,
                required: false,
                default: false
			},
            config: {
                type: Object,
                required: false,
                default: () => {},
			},

            onSortBtnClick: {
				type: Function,
				required: true,
			},
            onClickPageBtn: {
				type: Function,
				required: true,
			},
        },
        mixins: [mixUtils,],
        data: () => ({
            sortRangeBtn_0: 'pubs-search__tag--sort_range pubs-search__tag--sort_asc',
            sortRangeBtn_1: '',
            currentPage: 1,
        }),
        watch: {
			sortValue: function (newsortValue) {
                this.sortChanger(newsortValue);
			}
		},
        methods: {
            sortBtnClick (item) {
                if (this.onSortBtnClick) {
                    this.onSortBtnClick(item);
                }
            },
            clickPageBtn (page) {
                if (this.onClickPageBtn) {
					this.onClickPageBtn(page);
                    new Promise( resolve => setTimeout(() => {resolve(window.scrollTo(0,0))}, 500))
				}
            },
            pubsLabelChange(){
                return this.ruVersion
                    ? this.plural(this.totalFoundPubls, 'Найдено %s публикаций', 'Найдена %s публикация', 'Найдены %s публикации')
					: 'Of all publications in the section: %s'.replace(/%s/, this.totalFoundPubls.toLocaleString('ru-RU'));
            },
            sortChanger(sortValue){
                const sortBtn = this.sortBtns.filter(el=>el.value===sortValue)[0];
                if(sortBtn.value === "TITLE") {
                    this.sortRangeBtn_0 = (sortBtn.range === 'ASC') ? 'pubs-search__tag--sort_range pubs-search__tag--sort_asc' : 'pubs-search__tag--sort_range pubs-search__tag--sort_desc';
                    this.sortRangeBtn_1 = '';
                } else {
                    this.sortRangeBtn_0 = '';
                    this.sortRangeBtn_1 = (sortBtn.range === 'ASC') ? 'pubs-search__tag--sort_range pubs-search__tag--sort_asc' : 'pubs-search__tag--sort_range  pubs-search__tag--sort_desc';
                }

            }
        },
        mounted () {
            this.currentPage = this.currentPageId;
            this.sortChanger(this.sortValue);
        },
    };
</script>