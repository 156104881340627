import axios from 'axios'

export const mixDataLoader = {
	methods: {
		/**
		 * Поиск и загрузка сохранённого набора критериев поиска (raw_registry_publication_search_states)
		 * @param {*} params 
		 */
		async getSavedCriteria(url, params) {
			return await axios.post(url, params)
			.then(r => {
				const items = []
				if (r.data.status == 'ok') {
					for (const item of r.data.result) {
						items.push(item)
					}
				}
				return items
			})
			.catch(err => {
				console.log(`Error: ${err.message}`)
			})

		},
		spType(tp) {
			let res = 'ANY';
			switch (tp) {
				case BibitemBook:
					res = BOOK;
					break;
				default:
					res = 'ANY';
					break;
			}
			return res;
		},
		async getHttpResult(url) {
			// console.log(`>> url: ${url}`); 
			return axios.get(url, {withCredentials: true, headers: {"Content-Type": "application/json"}} )
				.then(r => {
					return r.data
				})
				.catch(err => {
					console.log(`Error getHttpResult: ${err.message}`)
					return null
				})
		},
	},
}