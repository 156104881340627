<template>
   	<div class="pubs-container">
		<page-comp
			:publs="chapter"
			:publs-type="publsType"
			:ru-version="ruVersion"
			:t = "t"
			:config="config"
		></page-comp>

		<slider-comp
			:item-list = "chapter.related"
			:slider-title = "t ? t.similarPublications : '...'"
			:publs-type = "publsType"
			:publs-id = "publsId"
			:items-cnt = "itemsCnt "
			:show-cover = "showCover"
			:ru-version = "ruVersion"
			:config="config"
		></slider-comp>

   	</div>
</template>

<script>
    import { mapState } from 'vuex';
	import PageComp from './components/pageComp.vue';
	import SliderComp from './components/sliderComp.vue';
	import { mixUtils } from './mixins/mixUtils.js';

    export default {
		name: 'chapter',
		components: {
			'page-comp': PageComp,
			'slider-comp': SliderComp,
		},
		metaInfo() {
			return {
				title: this.chapter && this.chapter.title ? this.chapter.title :'',
				meta: this.chapter && this.chapter.metaTegs ? this.chapter.metaTegs : [],
				lang: this.ruVersion ? 'ru' : 'en',
			}
		},
		props: {
			id: {
				type: String,
				required: false,
				default: '0',
			},
 		},
		mixins: [mixUtils,],
		data: () => ({
			currId: 0,
			publsType: "pChapter",
			publsId: "chapters",
			itemsCnt: 4,
			showCover: false,
		}),

		computed: mapState ({
			chapter: state => state.chapter,
			config: state => state.config,
			ruVersion: state => state.templateRuVersion,
			t: state => state.templateT,
		}),
		methods: {
			getChapter(baseURL) {
				return this.$store.dispatch('aLoadChapter', {params: {"id": this.currId}, baseURL});
			},
		},
		/**
		 * Сервер. Первичное гидрирование
		 */
		serverPrefetch () {
			this.currId = this.id
			this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
			return this.getChapter(this.config.serverBaseURL);
		},
		/**
		 * Слиент. Доп. загрузка
		 */
		mounted () {
			this.currId = this.id
			if (!this.chapter) {
				this.getChapter(this.config.clientBaseURL);
			}
		},

    };
</script>
