<template>
    <div class="pubs-page">
        <div class="b-row">
            <div v-if='publs' class="b-row__item b-row__item--8 b-row__item--t12 pubs-page__info">
                <h3 class="pubs-label pubs-label--type">{{pageTitle}}</h3>
                <h2 class="pubs-page__title">{{publs.title}}</h2>
                <div class="pubs-item__info i1">

                    <template v-if="publsType==='pBook'">
                        {{publs.description && publs.description.pages.publ && publs.description.pages.publ != '.' ? publs.description.pages.publ : ''}}
                        <span v-for='(plase, ind) in (publs.publisher && publs.publisher.places ? publs.publisher.places : [])' :key='ind'>
                            {{((ind-1) >= 0 ? ', ' : '') + (plase.abbr && langChoice(plase.abbr) ? langChoice(plase.abbr) : (plase.city && langChoice(plase.city) ? langChoice(plase.city) : '')) + ((ind === publs.publisher.places.length-1) && publs.publisher.places.length ? ' : ' : '' )}}
                        </span>
                        <a v-if="publs.publisher && publs.publisher.id" :href="`${getPrefixURLLang()}books/?pb=${publs.publisher.id}`">{{langChoice(publs.publisher.title) ? langChoice(publs.publisher.title) : ''}}</a>
                        <span v-else>{{langChoice(publs.publisher.title) ? langChoice(publs.publisher.title) : ''}}</span>{{publs.publisher && langChoice(publs.publisher.title) && publs.year ? ', ' : ''}}{{publs.year ? publs.year : ''}}.
                    </template>
                    <template v-if="publsType==='pArticle'">
                        <a v-if="publs.magazine && publs.magazine.id" :href="`${getPrefixURLLang()}articles/?mg=${publs.magazine.id}`">{{publs.magazine && extLangChoice(publs.magazine.title, publs.language.name == 'ru') ? extLangChoice(publs.magazine.title, publs.language.name == 'ru')+'. ' : ''}}</a>
                        <span v-else>{{extLangChoice(publs.magazine.title, publs.language.name == 'ru') ? extLangChoice(publs.magazine.title, publs.language.name == 'ru')+'. ' : ''}}</span>{{publs.year ? publs.year+'. ' : ''}}{{publs.description && publs.description.pages.publ && publs.description.pages.publ != '.' ? publs.description.pages.publ : ''}}
                    </template>

                    <template v-if="publsType==='pChapter' && publs.description && publs.description.pages.publ">
                        <span style="white-space: nowrap">{{publs.description.pages.publ}}</span>
                    </template>

                    <template v-if="publsType==='pPreprint'" >
                        <a :href="`${getPrefixURLLang()}${publs.series.href.ru}`" >
                            {{publs.series && langChoice(publs.series.title) ? langChoice(publs.series.title)+'.' : ''}}
                            {{publs.series && langChoice(publs.series.code) ? langChoice(publs.series.code)+'.' : ''}}
                            {{publs.series && publs.series.organization ? publs.series.organization : ''}}
                        </a>{{publs.series && publs.series.organization && publs.year ? ', ' : ''}}
                        {{publs.year ? publs.year+'. ' : ''}}
                        {{publs.description && publs.description.pages.publ && publs.description.pages.publ != '.' ? publs.description.pages.publ : ''}}
                    </template>
                </div>
                <div class="pubs-item__info i2">
                    <span v-for='(author, ind) in publs.authorsByType.author' :key='author.id'>
                        <span v-if="ind > 0">, </span><span v-else></span>
                        <a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${author.href}`" class="link">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
                        <span v-else>{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
                    </span>
                </div>
                <!-- authorsByType -->
                <div v-if='publs.authorsByType.leader' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Руководитель авторского коллектива:' : 'Leading author:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.leader' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href  && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>
                <div v-if='publs.authorsByType.sci_editor' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Научный редактор:' : 'Academic editor:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.sci_editor' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>
                <div v-if='publs.authorsByType.resp_editor' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Ответственный редактор:'  : 'Editor-in-chief:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.resp_editor' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>
                <div v-if='publs.authorsByType.cmn_editor' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Под общей редакцией:' : 'Under the general editorship:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.cmn_editor' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>
                <div v-if='publs.authorsByType.translator' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Переводчик:' : 'Translator:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.translator' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>
                <div v-if='publs.authorsByType.make_editor' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Составитель:' : 'Compiler:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.make_editor' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>
                <div v-if='publs.authorsByType.trn_editor' class="pubs-item__info i3">
                    <span>{{ ruVersion ? 'Научный редактор перевода:' : 'Editor of translation:'}}</span>
                    <span v-for='(editor, ind) in publs.authorsByType.trn_editor' :key='editor.id'>
                        <span v-if="ind > 0">, </span>
                        <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                        <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                    </span>
                </div>

                <div v-if="publs.status === 2" class="articleMetaItem">{{ruVersion ? 'В печати' : 'In press'}}</div>
                <div class="pubs-item__text" v-html="ruVersion ? publs.annotation.ru : publs.annotation.en"></div>
                <slider-comp
                    :item-list = "publs.chapters"
                    :sliderTitle = "ruVersion ? 'Главы книги' : 'Chapters'"
                    publs-type = "pChapters"
                    publs-id = "chapters"
                    :title-as-link = false
                    :items-cnt = "itemCnt"
                    :show-cover = "showCover"
                    :ru-version = "ruVersion"
                    :config = "config"
                ></slider-comp>

                <div class="column column-2">
                    <div class="column__item column__item--adp">
                        <div v-if="publs.researchTarget && publs.researchTarget.length > 0" class="articleMetaItem">
                            <span class="pubs-label">{{t.researchTarget}}:</span>
                            <a v-for='researchTarget in publs.researchTarget' :key='researchTarget.id' :href="`${getPrefixURLLang()}rubric/${researchTarget.id}`" class="link no-visited">
                                {{ruVersion ? researchTarget.title : researchTarget.en_title}}
                            </a>
                        </div>
                        <div v-if="publs.priorityTarget && publs.priorityTarget.length > 0" class="articleMetaItem">
                            <span class="pubs-label">{{t.priorityAreas}}:</span>
                            <a v-for='priorityTarget in publs.priorityTarget' :key='priorityTarget.id' :href="`${getPrefixURLLang()}search/index.html?dl=${priorityTarget.id}`" class="link no-visited">
                                {{ruVersion ? priorityTarget.title : priorityTarget.en_title}}
                            </a>
                        </div>
                        <div class="articleMetaItem">
                            <span class="pubs-label">{{t.language}}:</span>
                            <a :href="`${getPrefixURLLang()}search/?lang=${langChoice(publs.language.title)}`">
                                {{langChoice(publs.language.title)}}
                            </a>
                        </div>
                        <div v-if="publs && publs.documents && publs.documents.DEMO && publs.documents.DEMO.href" class="articleMetaItem">
                            <span :class="`file extra extra_inline ${publs.documents.DEMO.ext}`">
                                <a :href="`${publs.documents.DEMO.href}`" class="link" target="_blank">
                                    <span class="fileName">{{t.demoFile}}</span>
                                </a>
                            </span>
                        </div>
                        <div v-if="publs && publs.documents && publs.documents.DOCUMENT && publs.documents.DOCUMENT.href && showDocumentHref" class="articleMetaItem">
                            <span :class="`file extra extra_inline ${publs.documents.DOCUMENT.ext}`">
                                <a :href="`${publs.documents.DOCUMENT.href}`" class="link" target="_blank">
                                    <span class="fileName">{{t.completeText}}</span>
                                </a>
                            </span>
                        </div>
                        <div v-if="publs && publs.documents && publs.documents.DOI && publs.documents.DOI.href" class="articleMetaItem">
                            <span class="file extra extra_inline WEB">
                                <a :href="`${publs.documents.DOI.href}`" class="link" target="_blank">
                                    <span class="fileName">DOI</span>
                                </a>
                            </span>
                        </div>
                        <div v-if="publs && publs.documents && publs.documents.OTHER_URL && publs.documents.OTHER_URL.href" class="articleMetaItem">
                            <span class="file extra extra_inline WEB">
                            <!-- <span :class="`file extra extra_inline ${publs.documents.OTHER_URL.ext}`"> -->
                                <a :href="`${publs.documents.OTHER_URL.href}`" class="link" target="_blank">
                                    <span class="fileName">{{t.anotherSite}}</span>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="column__item column__item--adp">
                        <div v-if="publs.keywords && publs.keywords.length > 0" class="articleMetaItem articleMetaItem--tags">
                            <span class="pubs-label">{{t.keywords}}:</span>
                            <a v-for='keywords in publs.keywords' :key='keywords.id' :href="`${getPrefixURLLang()}search/index.html?kw=${keywords.id}`" class="rubric rubric--tag">
                                <span>{{keywords.title}}</span>
                            </a>
                        </div>
                        <div v-if="publs.projects && publs.projects.length > 0" class="articleMetaItem articleMetaItem--tags">
                            <span class="pubs-label">{{ruVersion ? 'ПУБЛИКАЦИЯ ПОДГОТОВЛЕНА ПО РЕЗУЛЬТАТАМ ПРОЕКТА' :'Publication based on the results of'}}:</span>
                            <div v-for='prg in publs.projects' :key='prg.project'>
                                <a  :href="`${getHseURL()}${ruVersion ? '/' : (prg.en_title ? '/en/' : '/')}org/projects/${prg.project}`" class="link">
                                    <span>{{clearSentens(ruVersion ? prg.title : (prg.en_title ? prg.en_title : prg.title))}}</span>{{prg.year ? ` (${prg.year})` : ''}}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div v-if="publs" class="b-row__item b-row__item--4 b-row__item--t12">
                <template v-if="publsType ==='pChapter'">
                    <h3 >{{t.inBook}}</h3>
                    <img v-if="publs.book.documents && publs.book.documents.COVER && publs.book.documents.COVER.href" 
                    class="pubs-page__img __iwc" 
                    :src="`${publs.book.documents.COVER.href}:r320x580${publs.book.documents.COVER.ext ? '.'+publs.book.documents.COVER.ext : ''}`" 
                    :srcset="`${publs.book.documents.COVER.href}:r540x1160${publs.book.documents.COVER.ext ? '.'+publs.book.documents.COVER.ext : ''} 2x`" 
                    :alt="`${publs.book.title}`"
                    >
                </template>
                <template v-if="publsType ==='pBook'">
                    <img v-if="publs.documents && publs.documents.COVER && publs.documents.COVER.href" 
                        class="pubs-page__img __iwc" 
                        :src="`${publs.documents.COVER.href}:r320x580${publs.documents.COVER.ext ? '.'+publs.documents.COVER.ext : ''}`" 
                        :alt="`${publs.title}`"
                        :srcset="`${publs.documents.COVER.href}:r540x1160${publs.documents.COVER.ext ? '.'+publs.documents.COVER.ext : ''} 2x`" 
                        >
                    <img v-else class="pubs-page__img __iwc" src="/f/src/pubs/default.png" :alt="`${publs.title}`">
                </template>

                <template v-if="publsType==='pChapter' && publs.book">
                    <div class="pubs-item__title">
                        <a v-if="publs.book.href" class="link link_dark" :href="`${getPrefixURLLang()+(publs.book.href.startsWith('/') ? publs.book.href.substring(1) : publs.book.href)}`">{{publs.book.title}}</a>
                        <span v-else>{{publs.book.title}}</span>
                    </div>

                    <div class="pubs-item__info i4">
                        <span v-for='(author, ind) in publs.book.authorsByType.author' :key='author.id'>
                            <span v-if="ind > 0">, </span>
                            <a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${author.href}`" class="link">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
                            <span v-else>{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
                        </span>
                        <template v-if='publs.book.authorsByType.cmn_editor'>
                            <span>{{t.editedBy}}:</span>
                            <span v-for='(editor, ind) in publs.book.authorsByType.cmn_editor' :key='editor.id'>
                                <span v-if="ind > 0">, </span>
                                <a v-if="editor.href && (!ruVersion && editor.enVersionStatus != 2 ? false : true )" :href="`${getHseURLLang()}${editor.href}`" class="link">{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</a>
                                <span v-else>{{editor && editor.altName ? editor.altName : (editor && editor.otherName ? editor.otherName :  (editor && editor.title ? langChoice(editor.title) : ''))}}</span>
                            </span>
                        </template>
                        <template v-if="publs.book.description">
                            <span v-if="publs.book.description.pages.publ && publs.book.description.pages.publ !== '.'" >{{publs.book.description.pages.publ}}</span>
                            <span v-if="publs.book.description.short.publ" >{{(publs.book.description.pages.publ && publs.book.description.pages.publ !== '.' ? ', ' : '') + publs.book.description.short.publ}}</span>
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <div class="b-row">
            <div class="b-row__item b-row__item--4 b-row__item--t12">
				<div class="ya-share2" data-services="vkontakte,twitter,telegram,whatsapp,viber" data-limit="5" data-lang="`${ruVersion ? 'ru' : 'en'}`"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import SliderComp from './sliderComp.vue';
    import { mixUtils } from '../mixins/mixUtils.js';
    import { mixDataLoader } from '../mixins/mixDataLoader.js';
    export default {
        name: 'PublsPage',
        components: {
			'slider-comp': SliderComp,
		},
        props: {
            publs: {
                type: Object,
                required: true,
                default: null
            },
            publsType: {
                type: ('pBook'|'pChapter'|'pArticle'|'pPreprint'),
                reuired: true,
            },
            ruVersion: {
                type: Boolean,
                required: true,
                default: true,
            },
            t: {
				type: Object,
				required: false,
				default: () => {},
			},
            config: {
                type: Object,
                required: false,
                default: () => {},
			},

        },
        mixins: [mixUtils, mixDataLoader,],
        data: () => ({
            pageTitle: '?',
            itemCnt: 2,
            showCover: false,
            showDocumentHref: true
        }),
        async mounted () {
            this.pageTitle = await this.titleChoice(this.publsType, this.ruVersion);
            if (this.publs && this.publs.documents && this.publs.documents.DOCUMENT && String(this.publs.documents.DOCUMENT.rules || ' ').indexOf('__guest') < 0) {
                 const res = await this.getHttpResult(`${this.getHseURL()}/api/auth/pubs/perms?fid=${this.publs.documents.DOCUMENT.id || 0}`)  ;
                 this.showDocumentHref = Boolean(res && res.ok === 1)
            }
        },
    };
</script>