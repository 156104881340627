
const sanitizeHtml = require('sanitize-html');

export const mixUtils = {
	methods: {
		tt(tag) {
			// console.log(`t: ${JSON.stringify(this.t,'', 4)}`)
			return this.t && this.t[tag] ? this.t[tag] : '--'
		},
		separateUrl() {
			const cu = this.$router && this.$router.currentRoute ? this.$router.currentRoute.fullPath.split('/') : ' ';
			this.curUrl = cu.includes('en') ? cu.slice(2).join('/') : cu.slice(1).join('/');
		},
		isEmpty(obj) {
			for (let key in obj) {
				// console.log(`key: ${key}`)
				return false;
			}
			return true;
		},
		dateFormatter(sDate, ruVersion = true) {
			const options = { year: 'numeric', 	month: 'long', day: 'numeric' };
			return (new Date(sDate)).toLocaleString(`${ruVersion ? 'ru-RU' : 'en-EN'}`, options)
		},
		langChoice(obj) {
			return this.extLangChoice(obj, this.ruVersion);
		},
		extLangChoice(obj, ruVersion) {
			if (!obj) {
				return '';
			}
			return ruVersion 
				? (obj.ru && obj.ru != '.' ? obj.ru : '')
				: (obj.en && obj.en != '.' ? obj.en : (obj.ru ? obj.ru : ''))
		},
		clearSentens(str) {
			if (!str || str===undefined || str==='null') {
				return ''
			};
			const options =  {
				allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])
			};
			
			return sanitizeHtml(str, options)
				.replace(/\<div.*?>/g, "")
				.replace(/\<\/div.*?>/g, "")
				.replace(/\<font.*?>/g, "")
				.replace(/\<\/font.*?>/g, "")
				.replace(/\<p.*?>/g, "")
				.replace(/\<\/p.*?>/g, "")
				.replace(/\<a.*?>/g, "")
				.replace(/\<\/a.*?>/g, "")
				.replace(/<\w*\/?>/g, '')
				.replace(/<\/\w*\/?>/g, '')
				.replace(/<br\W*\/?>/g, '')
				.replace(/<br\w*\/?>/g, '')
				.replace(/\&nbsp;/g, ' ')
				.replace(/\&gt;/g, '>')
				.replace(/\&lt;/g, '<')
				.replace(/\&ndash;/g, '-')
				.replace(/\&laquo;/g, '"')
				.replace(/\&ldquo;/g, '"')
				.replace(/\&rdquo;/g, '"')
				.replace(/\&lsquo;/g, '"')
				.replace(/\&rsquo;/g, '"')
				.replace(/\&raquo;/g, '"')
				.replace(/\&quot;/g, '"')
				.replace(/\&#39;/g, "'")
				.replace(/\&amp;/g, "&")
				.replace(/\&mdash;/g, '-')
				.replace(/\&Ouml;/g, 'O')
				.replace(/\&ouml;/g, 'o')
				.replace(/\&Auml;/g, 'A')
				.replace(/\&auml;/g, 'a')
				.replace(/\&Uuml;/g, 'U')
				.replace(/\&uuml;/g, 'u')
				.replace(/\&Euml;/g, 'E')
				.replace(/\&euml;/g, 'e')
				.replace(/\&copy;/g, '©')
				.replace(/\r/g, '\n')
				.replace(/<br\s*\/?>/g, '')
				.replace(/\-\n/g, '')
				.replace(//g, '')
				.replace(//g, '')
				// .replace(a.substring(a.indexOf('<'),a.indexOf('>')+1),'')
		},
		cutSentens(str, wordCnt = 55) {
			if (!str) {
				return ''
			}
			const clrStr = this.clearSentens(str)
			if (!clrStr) {
				return ''
			}
			return clrStr.split(' ')
				.map((itm,ind) => {
					if(ind < wordCnt) return itm
				})
				.join(' ')
				.trim()+ ' ...'
		},
		clearHTML(val) {
			if (!/\S/.test(val)) return '';
		
			val = val.replace(/\<div.*?\>\&nbsp;\<\/div\>/gm, '');
			val = val.replace(/^\s+|\s+$/gm, '');
			val = sanitizeHtml(val);
			val = val.replace(/[\s\n\r]+$/g, '');
		
			if (val === '<p>&nbsp;</p>' || val === '<p class="text">&nbsp;</p>')
				val = '';
		
			return val;
		},
		
		// const comment = clearHTML(person.json.tabsContent['comment.pubs']);
		// const hasComment = /\S/.test(comment);
		getPrefixURL() {
			return `${this.config.prefixURL}`
		},
		// urlPrefixPub() {
		getPrefixURLLang() {
			return `${!this.ruVersion ? '/en' : '' }${this.config.prefixURL}`
		},
		/**
		 * @returns 
		 */
		getPrefixPubsURLLang() {
			return `${this.config.publicationsURL ? this.config.publicationsURL : ''}${!this.ruVersion ? '/en' : '' }${this.config.domain !== 'p1.hse.ru' ? '/' : this.config.prefixURL}`
		},
		/**
		 * Должна заканчиваться на '/'
		 * @returns 
		 */
		getWidgetPrefixURLLang() {
			return `${!this.ruVersion ? '/en' : '' }${this.config.widgetPrefixURL + this.config.pubsUnit + this.config.widgetPostfixURL}${this.config.widgetPostfixURL && this.config.widgetPostfixURL.endsWith('/') ? '' : '/'}`;
		},
		// urlPrefixPers() { 
		getHseURL() { 
			return `${this.config.hseURL}`
		},
		// urlPrefixPersLang() { 
		getHseURLLang() { 
			return `${this.config.hseURL}${!this.ruVersion ? '/en' : '' }`
		},
		urlClientBase() { 
			return `${this.config.clientBaseURL}`
		},
		// urlPrefixDomain() {
		getDomainURL() {
			return this.config.domain;     // 'http://hse.local'
		},
		// home() {
		getDomainURLLang() {
			return `${this.config.domain}/${this.ruVersion ? '' : 'en/'}`
		},
		/**
		 * 
		 * @param {'Найдено 0 публикаций', 'Найдена 1 публикация', 'Найдены 32 публикации'} cnt 
		 * @param {*} v1 
		 * @param {*} v2 
		 * @param {*} v3 
		 */
		plural(cnt, v1,v2,v3) {
			const c = cnt % 100;
			let str;
			if (c >= 11 && c <= 19) {
				str=v3;
			} else {
				switch (cnt % 10) {
					case (1): str = v2; break;
					case (2):
					case (3):
					case (4): str = v3; break;
					default: str = v1;
				}
			};
			return str.replace(/%s/, cnt ? cnt.toLocaleString('ru-RU') : 0);
		},
		titleChoice(itemType, ruVersion) {
			let res;
			switch (itemType.toLowerCase()) {
				case 'pbook':
				case 'book': res = ruVersion ? 'Книга' : 'Book'
					break;
				case 'article':
				case 'particle': res = ruVersion ? 'Статья' : 'Article'
					break;
				case 'chapter':
				case 'pchapter': res = ruVersion ? 'Глава книги' : 'Сhapter'
					break;
				case 'preprint':
				case 'ppreprint': res = ruVersion ? 'Препринт' : 'Working Paper'
					break;
				default:
					res = ''
					break;
			}
			return res;
		},
		

	},
}