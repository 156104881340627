import Vue from 'vue';
import Router from 'vue-router';

import Pubs from './client/publications.vue';
import Article from './client/article.vue';
import Book from './client/book.vue';
import Chapter from './client/chapter.vue';
import Preprint from './client/preprint.vue';
import Search from './client/search.vue';
import Copyright from './client/copyright.vue';
import PubsUnit from './client/widgets/pubsUnit.vue';

Vue.use(Router); 


const createRouter = (context) => {
	let prefixURL = ' ';
	let widgetPrefixURL = '/';
	let widgetPostfixURL = '/';
	let pn = '/';
	let appId = 0;
	try {
		const wp = document?.currentScript?.dataset?.widgetPrefixUrl || null;
		appId = document?.currentScript?.dataset?.orgunitId || 1;
		pn = wp ? wp : window != undefined && window.location && window.location.pathname
	} catch(e) {
	}	
	try {
		prefixURL = context?.state?.config?.prefixURL || window?.__INITIAL_STATE__?.config?.prefixURL || '/';
		widgetPrefixURL = context?.state?.config?.widgetPrefixURL || window?.__INITIAL_STATE__?.config?.widgetPrefixURL || pn , // '/org/hse/'
		widgetPostfixURL = context?.state?.config?.widgetPostfixURL || window?.__INITIAL_STATE__?.config?.widgetPostfixURL || '/';
	} catch(e) {
		if (e.name === "ReferenceError") {
			prefixURL = process?.env?.HSE_PUBS_PREFIX_URL;
			widgetPrefixURL = process?.env?.HSE_PUBS_WIDGET_PREFIX  || '/';
			widgetPostfixURL = process?.env?.HSE_PUBS_WIDGET_POSTFIX  || '/';
		}
		else { console.log(e); }
	}

	const router = new Router({
		mode: 'history',
		routes: [
			{ path: `${prefixURL}`,	component: Pubs, name: 'pubs' },
			{ path: `/en${prefixURL}`,	component: Pubs, name: 'pubs-en' },

			{ path: `${prefixURL}books/page:pg.html`, component: Search,	name: 'search-pg-book', props: true, },
			{ path: `/en${prefixURL}books/page:pg.html`, component: Search,	name: 'search-pg-book-en', props: true, },
			{ path: `${prefixURL}books/:id`, component: Book,	name: 'book', props: true, },
			{ path: `/en${prefixURL}books/:id`, component: Book,	name: 'book-en', props: true, },
			{ path: `${prefixURL}books/`, component: Search,	name: 'search-book', props: true, },
			{ path: `/en${prefixURL}books/`, component: Search,	name: 'search-book-en', props: true, },

			{ path: `${prefixURL}articles/page:pg.html`, component: Search,	name: 'search-pg-articles', props: true, },
			{ path: `/en${prefixURL}articles/page:pg.html`, component: Search,	name: 'search-pg-articles-en', props: true, },
			{ path: `${prefixURL}articles/:id`, component: Article,	name: 'article', props: true, },
			{ path: `/en${prefixURL}articles/:id`, component: Article,	name: 'article-en', props: true, },
			{ path: `${prefixURL}articles/`, component: Search,	name: 'search-articles', props: true, },
			{ path: `/en${prefixURL}articles/`, component: Search,	name: 'search-articles-en', props: true, },

			{ path: `${prefixURL}chapters/page:pg.html`, component: Search,	name: 'search-pg-chapters', props: true, },
			{ path: `/en${prefixURL}chapters/page:pg.html`, component: Search,	name: 'search-pg-chapters-en', props: true, },
			{ path: `${prefixURL}chapters/:id`, component: Chapter,	name: 'chapter', props: true, },
			{ path: `/en${prefixURL}chapters/:id`, component: Chapter,	name: 'chapter-en', props: true, },
			{ path: `${prefixURL}chapters/`, component: Search,	name: 'search-chapters', props: true, },
			{ path: `/en${prefixURL}chapters/`, component: Search,	name: 'search-chapters-en', props: true, },

			{ path: `${prefixURL}preprints/page:pg.html`, component: Search,	name: 'search-pg-preprints', props: true, },
			{ path: `/en${prefixURL}preprints/page:pg.html`, component: Search,	name: 'search-pg-preprints-en', props: true, },
			{ path: `${prefixURL}preprints/:id`, component: Preprint,	name: 'preprint', props: true, },
			{ path: `/en${prefixURL}preprints/:id`, component: Preprint,	name: 'preprint-en', props: true, },
			{ path: `${prefixURL}preprints/`, component: Search,	name: 'search-preprints', props: true, },
			{ path: `/en${prefixURL}preprints/`, component: Search,	name: 'search-bpreprintsook-en', props: true, },

			{ path: `${prefixURL}search`, component: Search,	name: 'search', props: true, },
			{ path: `/en${prefixURL}search`, component: Search,	name: 'search-en', props: true, },
			{ path: `${prefixURL}search/index.html`, component: Search,	name: 'search-html', props: true, },
			{ path: `/en${prefixURL}search/index.html`, component: Search,	name: 'search-html-en', props: true, },

			{ path: `${prefixURL}copyright`, component: Copyright,	name: 'copyright', props: true, },
			{ path: `/en${prefixURL}copyright`, component: Copyright,	name: 'copyright-en', props: true, },

			{ path: `${prefixURL}rubric/:id`,	component: Pubs, name: 'rubric' },
			{ path: `/en${prefixURL}rubric/:id`,	component: Pubs, name: 'rubric-en' },

			
			{ path: `${widgetPrefixURL}`, component: PubsUnit},
			{ path: `/en${widgetPrefixURL}`, component: PubsUnit},

			{ path: `${widgetPrefixURL}:unit`, component: PubsUnit},
			{ path: `/en${widgetPrefixURL}:unit`, component: PubsUnit},

			{ path: `${widgetPrefixURL}:unit${widgetPostfixURL}`, component: PubsUnit},
			{ path: `/en${widgetPrefixURL}:unit${widgetPostfixURL}`, component: PubsUnit},

			{ path: `${widgetPrefixURL}:unit${widgetPostfixURL}search`, component: PubsUnit},
			{ path: `/en${widgetPrefixURL}:unit${widgetPostfixURL}search`, component: PubsUnit},

			{ path: `${widgetPrefixURL}:unit${widgetPostfixURL}search/page:pg.html`, component: PubsUnit},
			{ path: `/en${widgetPrefixURL}:unit${widgetPostfixURL}search/page:pg.html`, component: PubsUnit},
			
			{ path: `${widgetPrefixURL}:unit${widgetPostfixURL}search/index.html`, component: PubsUnit},
			{ path: `/en${widgetPrefixURL}:unit${widgetPostfixURL}search/index.html`, component: PubsUnit},

			// { path: `${prefixURL}widgetPubsMarked/:unit`, component: PubsUnit},
			// { path: `/en${prefixURL}widgetPubsMarked/:unit`, component: PubsUnit},
		],
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			};
			if (to.hash) { 
				return new Promise(resolve => setTimeout(() => resolve({selector: `div[id=app_${appId}`, behavior: 'smooth'}), 500))
			}
		}
	});
	return router;
};

export default createRouter;