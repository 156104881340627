<template>
	<div :class="containerClass" v-if="pageCount > 1">
		<div v-if="firstLastButton" :class="[pageClass, firstPageSelected() ? disabledClass : '']">
			<a @click="selectFirstPage()" @keyup.enter="selectFirstPage()" :class="pageLinkClass" :tabindex="firstPageSelected() ? -1 : 0" v-html="firstButtonText"></a>
		</div>
		<div v-if="!(firstPageSelected() && hidePrevNext)" :class="[prevClass, firstPageSelected() ? disabledClass : '']">
			<a @click="prevPage()" @keyup.enter="prevPage()" :class="prevLinkClass" :tabindex="firstPageSelected() ? -1 : 0" v-html="prevText"></a>
		</div>

		<template v-for="(page, ind) in pages">
			<span v-if="page.breakView" :key="`${ind}_a`" :class="[innerBreakViewLinkClass]" :tabindex="page.index">
			<!-- <span v-if="page.breakView" :key="`${JSON.stringify(page)}_a`" :class="[innerBreakViewLinkClass]" :tabindex="page.index"> -->
				<slot name="breakViewContent">{{ breakViewText }}</slot>
			</span>
			<a v-else-if="page.disabled" :key="`${ind}_b`" :class="[pageLinkClass, innerLink]" :tabindex="page.index">{{ page.content }}</a>
			<!-- <a v-else-if="page.disabled" :key="`${JSON.stringify(page)}_b`" :class="[pageLinkClass, innerLink]" :tabindex="page.index">{{ page.content }}</a> -->
			<a v-else :key="`${ind}_c`" @click="handlePageSelected(page.index + 1)" @keyup.enter="handlePageSelected(page.index + 1)" :class="[pageClass, page.selected ? activeClass : '', page.disabled ? disabledClass : '', page.breakView ? breakViewClass : innerLinkClass]" :tabindex="page.index">{{ page.content }}</a>
			<!-- <a v-else :key="`${JSON.stringify(page)}_c`" @click="handlePageSelected(page.index + 1)" @keyup.enter="handlePageSelected(page.index + 1)" :class="[pageClass, page.selected ? activeClass : '', page.disabled ? disabledClass : '', page.breakView ? breakViewClass : innerLinkClass]" :tabindex="page.index">{{ page.content }}</a> -->
		</template>

		<div v-if="(lastPageSelected() && hidePrevNext)" :class="[nextClass, lastPageSelected() || hidePrevNext ? disabledClass : '']">
			<a @click="nextPage()" @keyup.enter="nextPage()" :class="[nextLinkClass, innerLink]" :tabindex="lastPageSelected() ? -1 : 0" v-html="nextText"></a>
		</div>
		<div v-if="firstLastButton" :class="[pageClass, lastPageSelected() ? disabledClass : innerLinkClass]">
			<a @click="selectLastPage()" @keyup.enter="selectLastPage()" :class="[pageLinkClass, innerLink]" :tabindex="lastPageSelected() ? -1 : 0" v-html="lastButtonText"></a>
		</div>

	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
		},
		pageCount: {
			type: Number,
			required: true,
		},
		forcePage: {
			type: Number,
		},
		clickHandler: {
			type: Function,
			default: () => { },
		},
		pageRange: {
			type: Number,
			default: 3,
		},
		marginPages: {
			type: Number,
			default: 1,
		},
		prevText: {
			type: String,
			default: 'Prev',
		},
		nextText: {
			type: String,
			default: 'Next',
		},
		breakViewText: {
			type: String,
			default: '…',
		},
		containerClass: {
			type: String,
		},
		pageClass: {
			type: String,
		},
		pageLinkClass: {
			type: String,
		},
		prevClass: {
			type: String,
		},
		prevLinkClass: {
			type: String,
		},
		nextClass: {
			type: String,
		},
		nextLinkClass: {
			type: String,
		},
		breakViewClass: {
			type: String,
		},
		// breakViewLinkClass: {
		// 	type: String
		// },
		activeClass: {
			type: String,
			default: 'active',
		},
		disabledClass: {
			type: String,
			default: 'disabled',
		},
		// noLiSurround: {
		// 	type: Boolean,
		// 	default: false
		// },
		firstLastButton: {
			type: Boolean,
			default: false,
		},
		firstButtonText: {
			type: String,
			default: 'First',
		},
		lastButtonText: {
			type: String,
			default: 'Last',
		},
		hidePrevNext: {
			type: Boolean,
			default: false,
		},
		config: {
            type: Object,
            required: false,
            default: () => {},
		},

	},
	beforeUpdate() {
		if (this.forcePage === undefined) return
		if (this.forcePage !== this.selected) {
			this.selected = this.forcePage
		}
	},
	computed: {
		selected: {
			get: function() {
				return this.value || this.innerValue
			},
			set: function(newValue) {
				this.innerValue = newValue
			}
		},

		pages: function () {
			let items = {}
			if (this.pageCount <= this.pageRange) {
				for (let index = 0; index < this.pageCount; index++) {
					items[index] = {
						index: index,
						content: index + 1,
						selected: index === (this.selected - 1)
					}
				}
			} else {
				const halfPageRange = Math.floor(this.pageRange / 2)
				const setPageItem = index => {
					items[index] = {
						index: index,
						content: index + 1,
						selected: index === (this.selected - 1)
					}
				}
				const setBreakView = index => { // формирование '...'
					items[index] = {
						disabled: true,
						breakView: true
					}
				}
				// 1-я часть
				for (let i = 0; i < this.marginPages; i++) {
					setPageItem(i);
				}
				// 2-я часть
				let selectedRangeLow = 0;
				if (this.selected - halfPageRange > 0) {
					selectedRangeLow = this.selected - 1 - halfPageRange;
				}
				let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
				if (selectedRangeHigh >= this.pageCount) {
					selectedRangeHigh = this.pageCount - 1;
					selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
				}
				for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
					setPageItem(i);
				}
				if (selectedRangeLow > this.marginPages) {
					setBreakView(selectedRangeLow - 1)
				}
				if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
					setBreakView(selectedRangeHigh + 1)
				}
				// 3-я часть
				for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
					setPageItem(i);
				}
			}
			// console.log(`>>> Pages. items: ${JSON.stringify(items, '', 4)}`);
			return items
		}
	},
	data() {
		return {
			innerValue: 1,
			innerLink: 'inner-link',
			innerLinkClass: 'inner-link-class',
			innerBreakViewLinkClass: 'inner-break-class'
		}
	},
	methods: {
		handlePageSelected(selected) {
			if (this.selected === selected) return
			this.innerValue = selected
			this.$emit('input', selected)
			this.clickHandler(selected)
		},
		prevPage() {
			if (this.selected <= 1) return
			this.handlePageSelected(this.selected - 1)
		},
		nextPage() {
			if (this.selected >= this.pageCount) return
			this.handlePageSelected(this.selected + 1)
		},
		firstPageSelected() {
			return this.selected === 1
		},
		lastPageSelected() {
			// return (this.selected === this.pageCount) || (this.pageCount === 0)
			return this.selected === this.pageCount || this.pageCount === 0
		},
		selectFirstPage() {
			if (this.selected <= 1) return
			this.handlePageSelected(1)
		},
		selectLastPage() {
			if (this.selected >= this.pageCount) return
			this.handlePageSelected(this.pageCount)
		}
	}
}
</script>
