<template>
	<div class="fa-footer">
		<div class="fa-footer__menu">
			<div :class="`fa-footer__menu_inner b-row b-row--${navigationFooter && navigationFooter.groups ? navigationFooter.groups.length : ''}`">
				<ul v-for="col in navigationFooter.groups" :key="col.title" class="navigation b-row__item">
					<li class="navigation__item navigation__item--parent">
						<a class="navigation__link" :href="col.url">{{col.title}}</a> 
					</li>
					<li v-for="item in col.links" :key="item.title" class="navigation__item">
						<a class="navigation__link" :href="item.url">{{item.title}}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="fa-footer__menu with-indent3">
			<!-- noindex 1 -->
			<template v-if="links && links.links && links.links.length">
				<ul :class="`ul b-row b-row--${links.links.length}`">
					<li v-for="item in links.links" :key="item.title" class="b-row__item">
						<a :href="item.url" rel="nofollow">{{item.url}}</a>
						<div>{{item.title}}</div>
					</li>
				</ul>
			</template>
		</div>
		<!-- noindex  2 -->
		<div class="fa-footer__menu with-indent3 b-row">
			<!-- noindex 2a -->
			<ul class="ul b-row__item b-row__item--9 small navigation navigation_horizontal">
				<li class="navigation__item">
					<span  class="fa-grey">{{origin.siteTitleExtraShort + origin.siteCopyrightDate }}</span>
				</li>
				<template v-if="copyrightFooter && copyrightFooter.links && copyrightFooter.links.length">
					<li v-for="item in copyrightFooter.links" :key="item.title" class="navigation__item">
						<a :href="item.url">{{item.title}}</a>
					</li>
				</template>
			</ul>
			<!-- noindex 2b -->
			<div class="b-row__item b-row__item--3 small editor">
				<a class="link_dashed link_editor link_btm_editor" rel="nofollow" :href="`${origin.editURL}`">{{t.editURL}}</a>
			</div>
		</div>

	</div>
</template>

<script>
	import Vue from 'vue'
    import { mixUtils } from '../mixins/mixUtils.js';

    export default {
		name: 'PublsFooter',
        props: {
            navigationFooter: {
                type: Object,
                required: false,
                default: () => ({})
            },
            links: {
                type: Object,
                required: false,
                default: () => ({})
			},
			origin: {
				type: Object,
				required: false,
				default: () => ({})
			},
            copyrightFooter: {
                type: Object,
                required: false,
                default: () => ({})
			},
            t: {
                type: Object,
                required: false,
                default: () => ({})
			},
			config: {
                type: Object,
                required: false,
                default: () => {},
			},

		},
		mixins: [mixUtils],
    };
</script>