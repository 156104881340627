<template>
    <div class="pubs-list-item__info pubs-list-item_author">
        <template v-if="authorsByType && authorsByType.leader">
            <span v-for='(author, ind ) in authorsByType.leader' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.leader.prefix + (itemLanguage == 'ru' ? authorType.leader.ru : authorType.leader.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.leader.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.leader.length-1">.</template>
                </template>
            </span>
        </template>
        <template v-if="authorsByType && authorsByType.resp_editor">
            <span v-for='(author, ind ) in authorsByType.resp_editor' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.resp_editor.prefix + (itemLanguage == 'ru' ? authorType.resp_editor.ru : authorType.resp_editor.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.resp_editor.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.resp_editor.length-1">.</template>
                </template>
            </span>
        </template>
        <template v-if="authorsByType && authorsByType.sci_editor">
            <span v-for='(author, ind ) in authorsByType.sci_editor' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.sci_editor.prefix + (itemLanguage == 'ru' ? authorType.sci_editor.ru : authorType.sci_editor.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.sci_editor.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.sci_editor.length-1">.</template>
                </template>
            </span>
        </template>
        <template v-if="authorsByType && authorsByType.cmn_editor">
            <span v-for='(author, ind ) in authorsByType.cmn_editor' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.cmn_editor.prefix + (itemLanguage == 'ru' ? authorType.cmn_editor.ru : authorType.cmn_editor.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.cmn_editor.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.cmn_editor.length-1">.</template>
                </template>
            </span>
        </template>
        <template v-if="authorsByType && authorsByType.make_editor">
            <span v-for='(author, ind ) in authorsByType.make_editor' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.make_editor.prefix + (itemLanguage == 'ru' ? authorType.make_editor.ru : authorType.make_editor.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.make_editor.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.make_editor.length-1">.</template>
                </template>
            </span>
        </template>
        <template v-if="authorsByType && authorsByType.translator">
            <span v-for='(author, ind ) in authorsByType.translator' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.translator.prefix + (itemLanguage == 'ru' ? authorType.translator.ru : authorType.translator.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.translator.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.translator.length-1">.</template>
                </template>
            </span>
        </template>
        <template v-if="authorsByType && authorsByType.trn_editor">
            <span v-for='(author, ind ) in authorsByType.trn_editor' :key='author.id'>
                <span v-if="ind === 0" class="nowrap">{{authorType.trn_editor.prefix + (itemLanguage == 'ru' ? authorType.trn_editor.ru : authorType.trn_editor.en)}}</span>
                <span v-else class="nowrap">, </span>
                <template v-if="config.widgetName && config.widgetName.length > 0">
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itemLanguage === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.trn_editor.length-1">.</template>
                </template>
                <template v-else>
                    <a v-if="author.href && (Boolean(itemLanguage === 'en') && author.enVersionStatus != 2 ? false : true ) && config.author !== author.id" class="link" target="_blank" :href="`${config.hseURL}${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</a>
                    <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.reverseTitle ? extLangChoice(author.reverseTitle, Boolean(itemLanguage === 'ru')) : ''))}}</span><template v-if="ind == authorsByType.trn_editor.length-1">.</template>
                </template>
            </span>
        </template>{{tail}}
    </div>
</template>

<script>
    
    import { mixUtils } from '../mixins/mixUtils.js';
    export default {
        name: 'SearchResultAuthor',
        props: {
            authorsByType: {
                type: Array,
                required: false,
                default: () => [],
            },
            t: {
				type: Object,
				required: false,
				default: () => {},
			},
            itemLanguage: {
                type: String,
                required: true,
                default: 'ru',
            },
            ruVersion: {
                type: Boolean,
                required: true,
                default: true,
            },
            config: {
                type: Object,
                required: false,
                default: () => {},
			},
            tail: {
                type: String,
                required: true,
                default: '',
            },
            bibitem: {
                type: Number,
                required: false,
                default: 0,
            },

        },
        data: () => ({
            authorType: {
                leader: {prefix: '', ru: 'Рук.:', en: 'Lead author'},
                resp_editor: {prefix: '', ru: 'Отв. ред.:', en: 'Ed. by'},
                sci_editor: {prefix: '', ru: 'Науч. ред.:', en: 'Ed. by'},
                cmn_editor: {prefix: '', ru: 'Под общ. ред.:', en: 'Ed. by'},
                make_editor: {prefix: '', ru: 'Сост.:', en: 'Ed. by'},
                translator: {prefix: '', ru: 'Перев.:', en: 'transl.'},
                trn_editor: {prefix: '', ru: 'Науч. ред. перевода:', en: 'Trans. ed. by'},
            },
        }),  
        mixins: [mixUtils,],
        methods: { 
            setPrefix() {
                const keyTypes = Object.keys(this.authorType);
                for (const key of keyTypes) { // устанавливает '/' на первом заполненном prefix. Чтобы в перечислении был только первый
                    const abt = this.authorsByType[key];
                    if(abt && abt.length > 0) {
                        this.authorType[key].prefix = ' / ';
                        break;
                    }
                }
                if (this.itemLanguage === 'en') {
                    let edby = 0;
                    for (const key of keyTypes) { // Оставляет только главный, заполненный 'Ed. by', остальные стирает. Чтобы в перечислении был только первый.
                        const abt = this.authorsByType[key];
                        if(abt && abt.length > 0 && this.authorType[key].en === 'Ed. by') {
                            if (edby > 0) this.authorType[key].en = '';
                            edby++;
                        }
                    }
                }
            }
        },
        mounted () { 
            this.setPrefix();
        },
    };
</script>