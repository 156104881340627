<template>
	<div class="header-board js-mobile_popup row">
		<div class="header-board__top fa-sidebar__top--secondary js-mobile_popup__top is-fixed is-mobile">
			<div class="header-top fa-sidebar__top--secondary">
				<div class="left">
					<ul class="control_lang2">
						<li class="control_lang2_item activated first_child">
							<a class="link link_no-visited link_no-underline" :href="`${getDomainURL()}${curUrl}`">RU</a>
						</li>
						<li class="control_lang2_item first_child">
							<a class="link link_no-visited link_no-underline" :href="`${getDomainURL()}/en${curUrl}`">EN</a>
						</li>
					</ul>
					<a :href="`${getDomainURL()}/lkuser/`" class="control control_user control_white" title="Личный кабинет сотрудника ВШЭ">
						<ins>
							<svg class="control_svg" width="17" height="18" viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.702 13.175c.827.315 1.486.817 1.978 1.506.492.689.738 1.467.738 2.333h-16.419c0-1.417.532-2.5 1.595-3.248.394-.276 1.358-.591 2.894-.945.945-.118 1.457-.374 1.536-.768.039-.157.059-.61.059-1.358 0-.118-.039-.217-.118-.295-.157-.157-.315-.433-.472-.827-.079-.315-.157-.787-.236-1.417-.157.039-.285-.02-.384-.177-.098-.157-.177-.364-.236-.62l-.089-.443c-.157-.866-.098-1.28.177-1.24-.118-.157-.217-.532-.295-1.122-.118-.866-.059-1.634.177-2.303.276-.748.768-1.319 1.476-1.713.709-.394 1.476-.571 2.303-.532.787.039 1.506.276 2.156.709.65.433 1.093 1.024 1.329 1.772.197.551.217 1.319.059 2.303-.079.472-.157.768-.236.886.118-.039.207 0 .266.118.059.118.079.266.059.443l-.059.472c-.02.138-.049.246-.089.325l-.118.413c-.039.276-.108.472-.207.591-.098.118-.226.157-.384.118-.079.866-.217 1.476-.413 1.831 0 .039-.069.138-.207.295-.138.157-.207.256-.207.295v.65c0 .394.039.689.118.886.079.197.354.354.827.472.276.118.679.217 1.211.295.532.079.935.177 1.211.295z" fill="#1658DA"></path>
							</svg>
						</ins>
					</a>
					<span class="control control_white control_search js-search_mobile_control" title="Поиск">
						<ins>
							<svg class="control_svg" width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.927 7.9c0-1.384-.492-2.568-1.476-3.552s-2.168-1.476-3.552-1.476-2.568.492-3.552 1.476-1.476 2.168-1.476 3.552.492 2.568 1.476 3.552 2.168 1.476 3.552 1.476 2.568-.492 3.552-1.476 1.476-2.168 1.476-3.552zm4.053 11.1l-4.603-4.592c-1.339.928-2.832 1.391-4.477 1.391-1.07 0-2.093-.208-3.069-.623-.976-.415-1.818-.976-2.525-1.683-.707-.707-1.268-1.549-1.683-2.525-.415-.976-.623-1.999-.623-3.069 0-1.07.208-2.093.623-3.069.415-.976.976-1.818 1.683-2.525.707-.707 1.549-1.268 2.525-1.683.976-.415 1.999-.623 3.069-.623 1.07 0 2.093.208 3.069.623.976.415 1.818.976 2.525 1.683.707.707 1.268 1.549 1.683 2.525.415.976.623 1.999.623 3.069 0 1.646-.464 3.138-1.391 4.477l4.603 4.603-2.031 2.02z" fill="#1658DA"></path>
							</svg>
						</ins>
					</span>
				</div>
				<div class="right">
					<div class="header__controls">
						<span onclick="void(0)" class="control is-mobile control_menu control_menu--close js-control_menu_close"><ins></ins></span>
					</div>
				</div>
			</div>
			<div class="js-search_mobile_popup not_display is-mobile">
				<div class="search-form">
					<form action="`${getDomainURL()}${getPrefixURLLang()}search/index.html`">
						<div class="search-form__button">
							<button class="button button_grey ">Поиск</button>
						</div>
						<div class="search-form__input">
							<input type="text" name="text" placeholder="Поиск по сайту" class="input fa-search_input input100 input_mr">
							<input type="hidden" name="simple" value="1">
							<input name="searchid" type="hidden" value="2284688">
						</div>
					</form>
				</div>
				<div class="popup__block">
					<a :href="`${getDomainURL()}${getPrefixURLLang()}search/search.html?simple=0&amp;searchid=2284688`" class="link_white">Расширенный поиск</a>
				</div>
			</div>
			<div class="header-board__title">
				<a class="link_white link_no-underline" :href="`${getDomainURL()}${getPrefixURLLang()}`">
					<div class="logo__title">Высшая школа экономики</div>
					<span class="logo__subtitle">Национальный исследовательский университет</span>
				</a>
			</div>
		</div>

	<!-- +mobile_panel -->
		<template v-if="topMenu && topMenu.lenght" >
			<div class="header-board__inner js-mobile_popup__inner b-row b-row--1">
				<template v-for="(col, index) in topMenu">
					<ul v-if="col.hide == 0" :key="index" class="navigation navigation_header b-row__item">
						<li v-for="(item, index2) in col.item" :key="index2" class="navigation__item">
							<a v-if="item.hide == 0" class="navigation__link" :href="item.url">{{item.title}}</a>
						</li>
					</ul>
					<!-- <ul v-if="col.type.type == 'text' && col.type.text" :key="col.type.id" class="navigation navigation_header b-row__item">
						<li class="navigation__item">col.type.text</li>
					</ul> -->
				</template>
			</div>
		</template>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { mixUtils } from '../mixins/mixUtils.js';
    export default {
		name: 'PublsHeaderBord',
        props: {
            topMenu: {
                type: Array,
                required: false,
                default: [],
			},
			config: {
                type: Object,
                required: false,
                default: () => {},
			},

		},
		mixins: [mixUtils],
		data: () => ({
			curUrl: '',
		}),
        methods: { },
        mounted () {
			this.separateUrl();
		},
    };
</script>
