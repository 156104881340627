<template>

    <div class="tree-view">

        <context-menu v-if="contextMenu" :contextMenuItems="contextMenuItems"></context-menu>

        <drop-between-zone
                @nodeDrop="dropNodeAtPosition(0)"
                v-if="draggedNode !== null && draggedNode.data !== data[0]">
        </drop-between-zone>
        <template v-for="(nodeData, index) in data">
            <tree-node
                    :key="nodeData[nodeKeyProp]"
                    :keyProp="nodeKeyProp"
                    :renameOnDblClick="renameNodeOnDblClick"
                    :childrenProp="nodeChildrenProp"
                    :labelProp="nodeLabelProp"
                    :data="nodeData"
                    :draggable="nodesDraggable"
                    :defaultIconClass="defaultIconClass"
                    :iconClassProp="iconClassProp"
                    :showIcon="showIcons"
                    :prependIconClass="prependIconClass"
                    :contextMenu="contextMenu"
                    ref="rootNodes"
                    :hrefPrefix="hrefPrefix"
                    @nodeSelect="nodeSelect"
                    @nodeDragStart="nodeDragStart"
                    @deleteNode="deleteNode"
            ></tree-node>
            <drop-between-zone :key='index'
                @nodeDrop='dropNodeAtPosition(index + 1)' 
                v-if="draggedNode !== null && draggedNode.data !== nodeData && (index + 1 >= data.length || draggedNode.data !== data[index + 1])">
            </drop-between-zone>
        </template>

    </div>

</template>

<script>

    import TreeNode from './treeNode.vue';
    import EventBus from './eventBus.js';
    import DropBetweenZone from './dropBetweenZone.vue'
    import ContextMenu from './contextMenu.vue'

    export default {
        props: {
            data: {
                type: Array,
                required: true,
            },
            allowMultiple: {
                type: Boolean,
                default: false
            },
            nodeKeyProp: {
                type: String,
                default: 'id'
            },
            nodeChildrenProp: {
                type: String,
                default: 'children'
            },
            nodeLabelProp: {
                type: String,
                default: 'name'
            },
            nodesDraggable: {
                type: Boolean,
                default: false
            },
            contextMenu: {
                type: Boolean,
                default: false
            },
            contextMenuItems: {
                type: Array,
                default: () => ([{code: 'DELETE_NODE', label: 'Delete node'}, {code: 'RENAME_NODE', label: 'Rename node'}])
            },
            renameNodeOnDblClick: {
                type: Boolean,
                default: false
            },
            // class added to every icon no matter what
            prependIconClass: {
                type: String,
                default: null
            },
            // default icon if node icon is not specified
            defaultIconClass: {
                type: String,
                default: null
            },
            // where to search for node icon
            iconClassProp: {
                type: String,
                default: "icon"
            },
            // show icons
            showIcons: {
                type: Boolean,
                default: true
            },
            hrefPrefix: {
                type: String,
                default: "/"
            },
        },
        //name: 'tree-node',
        data: () => ({
            draggedNode: null,
            selectedNode: null,
        }),
        components: {
            TreeNode,
            DropBetweenZone,
            ContextMenu
        },
        methods: {
            createNodeMap() {
                if (this.nodeMap === undefined) {
                    const nodeMap = this.nodeMap = new Map()
                    const nodes = this.$refs && this.$refs.rootNodes ? this.$refs.rootNodes.slice() : []

                    for (let i = 0; i < nodes.length; i++) {
                        let tmpNode = nodes[i]
                        nodes.push(...tmpNode.getChildNodes())
                    }
                    for (let tmpNode of nodes) {
                        nodeMap.set(tmpNode.data[this.nodeKeyProp], tmpNode) // TODO: change to getter
                    }
                }
            },
            getNodeByKey(key) {
                return this.nodeMap.get(key)
            },
            /**
             * Очистка данных от выделения (selected = false)
             */
            deselectAllData(data) {
                for (const el of data) {
                    el.selected = false;
                    if (el[this.nodeChildrenProp] && el[this.nodeChildrenProp].length) {
                        this.deselectAllData(el[this.nodeChildrenProp]);
                    }
                }
            },
            // event bubbles up to the roots
            nodeSelect(node, isSelected) {
                this.deselectAllData(this.data);
                this.$emit('nodeSelect', node, isSelected)
                if (isSelected) {
                    if (this.selectedNode !== null) {
                        this.selectedNode.deselect()
                    }
                    this.selectedNode = node
                } else if (node === this.selectedNode) {
                    this.selectedNode = null
                }
            },
            nodeDragStart() {
                EventBus.$on('dropOK', this.cutNode)
            },
            cutNode() {
                EventBus.$off('dropOK')
                let idx = this.data.indexOf(window._bTreeView.draggedNodeData)
                this.data.splice(idx, 1)
                EventBus.$emit('cutOK')
            },
            draggingStarted(draggedNode) {
                if (this.nodesDraggable) {
                    this.draggedNode = draggedNode
                    EventBus.$on('nodeDragEnd', this.draggingEnded)
                }
            },
            draggingEnded() {
                if (this.nodesDraggable) {
                    EventBus.$off('nodeDragEnd', this.draggingEnded)
                    this.draggedNode = null
                }
            },
            dropNodeAtPosition(pos) {
                if (this.nodesDraggable) {
                    let insertAfter = pos - 1 < 0 ? null : this.data[pos - 1]
                    EventBus.$on('cutOK', () => {
                        let pos = this.data.indexOf(insertAfter) + 1
                        this.data.splice(pos, 0, window._bTreeView.draggedNodeData)
                        delete window._bTreeView.draggedNodeKey
                        delete window._bTreeView.draggedNodeData
                        EventBus.$off('cutOK');
                    })
                    EventBus.$emit('dropOK')
                }
            },
            deleteNode(nodeData) {
                let nodes = this.data
                let idx = nodes.indexOf(nodeData)
                nodes.splice(idx, 1)
            },
            menuItemSelected(item, node) {
                switch (item.code) {
                    case 'DELETE_NODE':
                        node.delete()
                        this.$emit('contextMenuItemSelect', item, node)
                        break;
                    case 'RENAME_NODE':
                        node.startRenaming()
                        break;
                    default:
                        this.$emit('contextMenuItemSelect', item, node)
                }
            },
             saveItem(node) {
                 this.$emit('saveNodeItem', node)
             },
        },
        created() {
            this.selectedNode = null
            EventBus.$on('nodeDragStart', this.draggingStarted)
            EventBus.$on('contextMenuItemSelect', this.menuItemSelected)
            EventBus.$on('saveNodeItem', this.saveItem)
            this.$nextTick(() => {
                this.createNodeMap()
            })
        },
        mounted() {
            // this.$nextTick(() => {
            //   this.createNodeMap()
            // })
        },
    }


</script>
