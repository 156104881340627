<template>
    <div class="search-result">
        <!-- <div class="pubs-result_info column-3"> -->
        <div class="pubs-result_info">
			<div v-if="showFoundPublications" class="column__item"><span class="pubs-label">{{pubsLabelChange()}}</span></div>
			<div v-if="showSortBtn" class="column__item column__item--2_3">
				<div class="pubs-search__category">{{ruVersion ? 'Сортировка' : 'Sort'}}:</div>

				<div v-for='btn in sortBtns' :key='btn.id' class="pubs-search__sort">
                    <input
                        type="radio"
                        class="pubs-search__tag--sort"
                        :id="`pubs-search__sort_${config && config.pubsUnit ? config.pubsUnit : ''}_${btn.id}`"
                        :value="`${btn.value}`"
                        name="search-result"
                        @click="sortBtnClick(btn)"
                        v-model="sortValue" 
                    >
                    <label :for="`pubs-search__sort_${config && config.pubsUnit ? config.pubsUnit : ''}_${btn.id}`" :class="`pubs-search__tag--sort ${btn.id === 0 ? sortRangeBtn_0 : sortRangeBtn_1}`">{{ ruVersion ? btn.ruLabel : btn.enLabel }}</label>
				</div>
			</div>

		</div>
		<div class="pubs-result">
			<div v-for='itm in (itemList && itemList.length)  ? itemList : []' :key="itm.id" class="column__item">
                <div class="pubs-item__category">{{titleChoice(itm.type, ruVersion)}}</div>
                <a v-if="showCover" class="link link_no-underline" :href="`${getPrefixPubsURLLang()}view/${itm.id}`" target="_blank">
                    <img class="pubs-item__img __iwc" 
                    :src="`${itm.documents.COVER && itm.documents.COVER.href ? itm.documents.COVER.href+`:r135x200${itm.documents.COVER.ext ? '.'+itm.documents.COVER.ext : ''}` : '/f/src/pubs/default.png'}`" 
                    :alt="`${itm.title}`"
                    :srcset="`${itm.documents.COVER && itm.documents.COVER.href ? itm.documents.COVER.href+`:r270x400${itm.documents.COVER.ext ? '.'+itm.documents.COVER.ext : ''} 2x` : '/f/src/pubs/default.png'}`"
                    >
                </a>
                <div class="pubs-item__title">
                    <template v-if="config.widgetName && config.widgetName.length > 0">
                        <a v-if="!itm.is_hidden" class="link link_dark" :href="`${getPrefixPubsURLLang()}view/${itm.id}`" target="_blank">{{itm.title}}</a>
                        <span v-else class="nowrap">{{itm.title}}</span>
                    </template>    
                    <template v-else>
                        <a v-if="!itm.is_hidden" class="link link_dark" :href="`${getPrefixPubsURLLang()}view/${itm.id}`" target="_blank">{{itm.title}}</a>
                        <span v-else class="nowrap">{{itm.title}}</span>
                    </template>    
                </div>

                <div class="pubs-item__info">
                    <span v-for='(author, ind ) in itm && itm.authorsByType && itm.authorsByType.author ? itm.authorsByType.author : []' :key='author.id'>
                        <template v-if="ind < 3">
                            <span v-if="ind > 0" class="nowrap">, </span>
                            <template v-if="config.widgetName && config.widgetName.length > 0">
                                <a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${config.hseURL}${!ruVersion ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
                                <span v-else class="nowrap pubs-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
                            </template>
                            <template v-else>
                                <a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${getHseURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
                                <span v-else class="nowrap pubs-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
                            </template>
                        </template>
                        <span v-else-if="ind == 3"> и др.</span>
                    </span>{{itm && itm.authorsByType && itm.authorsByType.author && itm.authorsByType.author.length ? ', ' : ''}}{{itm.description.short.publ}}
                </div>
                <div class="pubs-item__desc is-truncated" style="overflow-wrap: break-word;">{{cutSentens(langChoice(itm.annotation))}}</div>
                <div class="pubs-item__date">{{(ruVersion ? 'Добавлено: ': 'Added: ') + dateFormatter(itm.createdAt, ruVersion)}}</div>
            </div>
		</div>
        <ssr-paginator
            v-model="currentPage"
            :page-count="totalFoundPages"
            :page-range="10"
            :marginPages=1
            :clickHandler="clickPageBtn"
            container-class="pages page-list"
            page-class="pages__page"
            page-link-class = "link"
            :first-last-button = false
            :hide-prev-next = true
            :prev-text="''"
            :next-text="''"
            :config="config"
        ></ssr-paginator>
    </div>
</template>

<script>
    import SsrPaginator from './ssrPaginator.vue';
    import { mixUtils } from '../mixins/mixUtils.js';
    export default {
        name: 'SearchResult',
        components: {
			'ssr-paginator': SsrPaginator,
		},
        props: {
            itemList: {
                type: Array,
                required: false,
                default: () => [],
            },
            totalFoundPubls: {
                type: Number,
                required: false,
                default: 1,
            },
            totalFoundPages: {
                type: Number,
                required: false,
                default: 1,
            },
            currentPageId: {
                type: Number,
                required: false,
                default: 1,
            },
            t: {
				type: Object,
				required: false,
				default: () => {},
			},
            ruVersion: {
                type: Boolean,
                required: true,
                default: true,
            },
            sortBtns: {
                type: Array,
                required: false,
                default: () => [],
            },
            sortValue: {
				type: String,
				required: false,
				default: 'TITLE',
            },
            showSortBtn: {
                type: Boolean,
                required: false,
                default: true,
			},
            showCover: {
                type: Boolean,
                required: false,
                default: false
			},
            showFoundPublications: {
                type: Boolean,
                required: false,
                default: false
			},
            config: {
                type: Object,
                required: false,
                default: () => {},
			},

            onSortBtnClick: {
				type: Function,
				required: true,
			},
            onClickPageBtn: {
				type: Function,
				required: true,
			},
        },
        mixins: [mixUtils,],
        data: () => ({
            sortRangeBtn_0: 'pubs-search__tag--sort_range pubs-search__tag--sort_asc',
            sortRangeBtn_1: '',
            currentPage: 1,
        }),
        watch: {
			sortValue: function (newsortValue) {
                this.sortChanger(newsortValue);
			}
		},
        methods: {
            sortBtnClick (item) {
                if (this.onSortBtnClick) {
                    this.onSortBtnClick(item);
                }
            },
            clickPageBtn (page) {
                if (this.onClickPageBtn) {
					this.onClickPageBtn(page);
                    new Promise( resolve => setTimeout(() => {resolve(window.scrollTo(0,0))}, 500))
				}
            },
            pubsLabelChange(){
                return this.ruVersion
                    // ? this.plural(this.totalFoundPubls, 'Найдено %s публикаций', 'Найдена %s публикация', 'Найдены %s публикации')
                    ? this.plural(this.totalFoundPubls, '%s публикаций', '%s публикация', '%s публикации')
					: 'Of all publications in the section: %s'.replace(/%s/, this.totalFoundPubls.toLocaleString('ru-RU'));
            },
            sortChanger(sortValue){
                const sortBtn = this.sortBtns.filter(el=>el.value===sortValue)[0];
                if(sortBtn.value === "TITLE") {
                    this.sortRangeBtn_0 = (sortBtn.range === 'ASC') ? 'pubs-search__tag--sort_range pubs-search__tag--sort_asc' : 'pubs-search__tag--sort_range pubs-search__tag--sort_desc';
                    this.sortRangeBtn_1 = '';
                } else {
                    this.sortRangeBtn_0 = '';
                    this.sortRangeBtn_1 = (sortBtn.range === 'ASC') ? 'pubs-search__tag--sort_range pubs-search__tag--sort_asc' : 'pubs-search__tag--sort_range  pubs-search__tag--sort_desc';
                }

            }
        },
        mounted () {
            this.currentPage = this.currentPageId;
            this.sortChanger(this.sortValue);
        },
    };
</script>