<template>
    <div class="pubs-list-items">
        <div v-for='itm in (itemList && itemList.length)  ? itemList : []' :key="itm.id" class="pubs-list-item">
            <a v-if="showCover && itm.documents.COVER && itm.documents.COVER.href" class="link link_no-underline pubs-list-hangover" :href="`${getPrefixPubsURLLang()}view/${itm.id}`" target="_blank">
                <img class="pubs-list-item__img __iwc" :src="`${itm.documents.COVER.href+'x50'}`">
            </a>
            <div class="pubs-list-item__content">
                <div class="pubs-list-item__category i2gr">{{titleChoice(itm.type, ruVersion)}}</div>
                <div :class="`pubs-list-item__info itm_${itm.id}`">
                    <span v-for='(author, ind ) in itm && itm.authorsByType && itm.authorsByType.author ? itm.authorsByType.author : []' :key='author.id'>
                            <span v-if="ind > 0" class="nowrap">, </span>
                            <template v-if="config.widgetName && config.widgetName.length > 0">
                                <a v-if="author.id !== config.author && author.href && (Boolean(itm.language.name === 'en') && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${config.hseURL}${Boolean(itm.language.name === 'en') ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? extLangChoice(author.title, Boolean(itm.language.name === 'ru')) : ''))}}</a>
                                <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? extLangChoice(author.title, Boolean(itm.language.name === 'ru')) : ''))}}</span>
                            </template>
                            <template v-else>
                                <a v-if="author.href && (Boolean(itm.language.name === 'en') && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${getPrefixURLLang()}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? extLangChoice(author.title, Boolean(itm.language.name === 'ru')) : ''))}}</a>
                                <span v-else class="nowrap pubs-list-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? extLangChoice(author.title, Boolean(itm.language.name === 'ru')) : ''))}}</span>
                            </template>
                    </span>
                </div>
                <div class="pubs-list-item__title">
                    <template v-if="config.widgetName && config.widgetName.length > 0"> 
                        <a v-if="!itm.is_hidden" class="link" :href="`${getPrefixPubsURLLang()}view/${itm.id}`" target="_blank">{{itm.title}}</a>
                        <span v-else class="nowrap">{{itm.title}}</span>
                    </template>
                    <template v-else>
                        <a v-if="!itm.is_hidden" class="link" :href="`${getPrefixPubsURLLang()}view/${itm.id}`" target="_blank">{{itm.title}}</a>
                        <span v-else class="nowrap">{{itm.title}}</span>
                    </template>
                </div>
                <div class="pubs-list-item__info">
                    <search-result-author
                        :authorsByType="itm.authorsByType"
                        :t="t"
                        :itemLanguage="itm.language.name"
                        :ruVersion="ruVersion"
                        :config="config"
                    ></search-result-author>
                    <span v-if="itm.language.name == 'ru'" class="i1b">{{itm.description.shortLeft.ru.trim()}}{{itm.description.shortLeft.ru.trim().endsWith('.') ? '' : '.'}}</span>
                    <span v-else class="i2b" >{{itm.description.shortLeft.en.trim()}}{{itm.description.shortLeft.en.trim().endsWith('.') ? '' : '.'}}</span>
                    <!-- <span v-if="itm.language.name == 'ru' && itm.type === 'CHAPTER' && itm.book.authorsByType" class="nowrap"> -->
                    <span v-if="itm.type === 'CHAPTER' && itm.book.authorsByType" class="nowrap">
                        <search-result-author
                        :authorsByType="itm.book.authorsByType"
                        :t="t"
                        :itemLanguage="itm.language.name"
                        :ruVersion="ruVersion"
                        :config="config"
                        :bibitem= "itm.id"
                        ></search-result-author>
                    </span>{{itm.description.shortRight.publ.trim()}}

                    <a v-if="itm.book.documents && itm.book.documents.DOI && itm.book.documents.DOI.href" target="_blank" class="link force_external a1" :href="`${itm.book.documents.DOI.href}`" style="font-size: 11px;">doi</a> 
                    <span v-if="itm.description.pages.publ.trim() !== '.'" class="i0b">{{itm.description.pages.publ}}</span>
                    <a v-if="itm.documents && itm.documents.DOI && itm.documents.DOI.href" target="_blank" class="link force_external a2" :href="`${itm.documents.DOI.href}`" style="font-size: 11px;">doi</a>
                    <span v-if="itm.status === 2" class="i">{{ ruVersion ? '(в печати)' : '(in press)'}}</span>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
    import { mixUtils } from '../mixins/mixUtils.js';
    import SearchResultAuthorComp from "./searchResultAuthorComp.vue";
    export default {
        name: 'SearchResultListItem',
        components: {
			"search-result-author": SearchResultAuthorComp,
		},
        props: {
            itemList: {
                type: Array,
                required: false,
                default: () => [],
            },
            t: {
				type: Object,
				required: false,
				default: () => {},
			},
            ruVersion: {
                type: Boolean,
                required: true,
                default: true,
            },
            showCover: {
                type: Boolean,
                required: false,
                default: false
			},
            config: {
                type: Object,
                required: false,
                default: () => {},
			},
        },
        mixins: [mixUtils,],
        methods: { },
        mounted () { },
    };
</script>