<template>
    <div class="tree-branch"
         :class="{ selected: selected || this.data.selected }">
        <div class="tree-node"
             :class="{ 'has-child-nodes': (hasChildren && !hasSelected), 'tree-node-expanded': nodeExpand(), 'drop-active': nodeDragOver }"
             @drop.prevent="drop"
             @dragover.prevent="dragover"
             :draggable="draggable && !renaming"
             @dragstart.stop="dragstart"
             @dragend="dragend"
             @dragenter.prevent.stop="dragEnter"
             @dragleave.prevent.stop="dragLeave"
             @contextmenu="showContextMenu($event)">
            <transition name="rotateArrow">
                <svg width="12"
                     height="12"
                     @click.prevent="toggle"
                     class="tree-node-icon"
                     v-show="hasChildren && !hasSelected"
                     >
                    <path d="M3 5 L7 7 L3 9 Z" class="svg-icon"/>
                </svg>
            </transition>
            <span class="tree-node-label"
                  @click="toggleSelection"
                  @dblclick="dblClickLabel">
                <i :class="['label-icon', prependIconClass, iconClass]" v-if="showIcon && iconClass !== null"></i>
                <input class="form-control form-control-sm input-rename"
                       ref="inputRename"
                       type="text"
                       v-model="renameNewLabel"
                       v-if="renaming"
                       v-focus
                       v-select-text
                       @blur="endRenaming"
                       v-on:keyup.esc.stop="cancelRenaming"
                       v-on:keyup.enter.stop="endRenaming">
                <!-- <span v-else>{{ data[labelProp] }}</span> -->
                <a v-else :href="`${hrefPrefix}${data[keyProp]}`">{{ data[labelProp] }}</a>
            </span>
        </div>
        <div class="tree-node-children"
             v-show="expanded && data[childrenProp] && Array.isArray(data[childrenProp])">
            <drop-between-zone @nodeDrop="dropNodeAtPosition(0)"
                               v-if="!dropDisabled && draggedNode !== null && data[childrenProp] && draggedNode.data !== data[childrenProp][0]">
            </drop-between-zone>
            <template v-for="(nodeData, index) in data[childrenProp]">
                <tree-node
                        :data="nodeData"
                        :key="nodeData[keyProp]"
                        ref="childNodes"
                        :keyProp="keyProp"
                        :labelProp="labelProp"
                        :childrenProp="childrenProp"
                        :renameOnDblClick="renameOnDblClick"
                        :draggable="draggable"
                        :defaultIconClass="defaultIconClass"
                        :iconClassProp="iconClassProp"
                        :showIcon="showIcon"
                        :prependIconClass="prependIconClass"
                        :contextMenu="contextMenu"
                        :hrefPrefix="hrefPrefix"
                        @nodeSelect="childNodeSelect"
                        @nodeDragStart="nodeDragStart"
                        @deleteNode="deleteChildNode">
                </tree-node>
                <drop-between-zone :key='index'
                        @nodeDrop="dropNodeAtPosition(index + 1)"
                        v-if="!dropDisabled && draggedNode && draggedNode.data !== nodeData && (index + 1 >= data[childrenProp].length || draggedNode.data !== data[childrenProp][index + 1])">
                </drop-between-zone>
            </template>
        </div>
    </div>
</template>

<script>
    import EventBus from './eventBus';
    import DropBetweenZone from './dropBetweenZone.vue';
    import Vue from 'vue'

    export default {
        name: 'tree-node',
        components: {
            DropBetweenZone
        },
        props: {
            data: {
                type: Object,
                required: true
            },
            keyProp: {
                type: String,
                default: 'id'
            },
            labelProp: {
                type: String,
                default: 'name'
            },
            childrenProp: {
                type: String,
                default: 'children'
            },
            draggable: {
                type: Boolean,
                default: false
            },
            renameOnDblClick: {
                type: Boolean,
                default: false
            },
            // default icon if node icon is not specified
            defaultIconClass: {
                type: String,
                default: null
            },
            // where to search for node icon
            iconClassProp: {
                type: String,
                default: null
            },
            // show icon
            showIcon: {
                type: Boolean,
                default: false
            },
            // class added to every icon no matter what
            prependIconClass: {
                type: String,
                default: null
            },
            contextMenu: {
                type: Boolean,
                default: true
            },
            hrefPrefix: {
                type: String,
                default: "/"
            },
        },
       data: () => ({
                expanded: false,
                selected: false,
                nodeDragOver: false,
                enterLeaveCounter: 0,
                draggedNode: null,
                dropDisabled: false,
                renaming: false,
                renameNewLabel: '',
        }),
        directives: {
            focus: {
                inserted(el) {
                    el.focus()
                }
            },
            selectText: {
                inserted(el) {
                    el.select()
                }
            }
        },
        watch: {
            selected(selected) {
                this.$emit('nodeSelect', this, selected || this.data.selected)
            },
            dropDisabled(disabled) {
                this.$emit(disabled ? 'dropDisabled' : 'dropEnabled')
            },
            nodeDragOver(dragover) {
                if (dragover) {
                    // check if node has any children, if yes then expand it after 1 sec
                    if (!this.expanded && Array.isArray(this.data[this.childrenProp]) && this.data[this.childrenProp].length > 0) {
                        this.expandNodeTimeout = setTimeout(this.toggle, 1000)
                    }
                } else if (this.expandNodeTimeout) {
                    clearTimeout(this.expandNodeTimeout)
                }
            }
        },
        computed: {
            hasChildren() {
                return this.data[this.childrenProp] !== undefined && this.data[this.childrenProp].length > 0;
            },
            hasSelected() {
                return this.data.selected;
            },
            iconClass() {
                return this.iconClassProp && this.data[this.iconClassProp] !== undefined
                    ? this.data[this.iconClassProp] : this.defaultIconClass;
            }
        },
        methods: {
            toggle() {
                if (this.data[this.childrenProp] && Array.isArray(this.data[this.childrenProp]) && this.data[this.childrenProp].length > 0) {
                    this.expanded = !this.expanded
                }
            },
            toggleSelection() {
                if (!this.renaming) {
                    this.toggle();
                    this.selected = !this.selected;
                    this.expand();
                }
            },
            select() {
                if (!this.renaming) {
                    this.selected = true
                }
            },
            deselect() {
                if (!this.renaming) {
                    this.selected = false
                }
            },
            expand() {
                if (this.data[this.childrenProp] && Array.isArray(this.data[this.childrenProp]) && this.data[this.childrenProp].length > 0) {
                    this.expanded = true
                }
            },
            collapse() {
                this.expanded = false
            },
            childNodeSelect(node, isSelected) {
                this.$emit('nodeSelect', node, isSelected)
            },
            nodeDragStart() {
                EventBus.$on('dropOK', this.cutNode)
            },
            cutNode() {
                EventBus.$off('dropOK')
                let idx = this.data[this.childrenProp].indexOf(window._bTreeView.draggedNodeData)
                this.data[this.childrenProp].splice(idx, 1)
                EventBus.$emit('cutOK')
            },
            getChildNodes() {
                return this.$refs.childNodes || []
            },
            dragstart(ev) {
                 if (this.draggable) {
                    this.dropDisabled = true
                    ev.dataTransfer.dropEffect = 'none'
                    this.$emit('nodeDragStart')
                    EventBus.$emit('nodeDragStart', this)
                    // dataTransfer не поддерживает ie т.к. не доступен в dragover событии!!!
                    if (window._bTreeView === undefined) {
                        window._bTreeView = {}
                    }
                    window._bTreeView.draggedNodeData = this.data
                    window._bTreeView.draggedNodeKey = this.data[this.keyProp]
                 }
            },
            // drop(ev) {
            drop() {
                if (this.draggable) {
                    if (this.data[this.childrenProp] === undefined) {
                        Vue.set(this.data, this.childrenProp, [])
                    }
                    // append node
                    this.dropNodeAtPosition(this.data[this.childrenProp].length)
                    this.nodeDragOver = false
                }
            },
            dragEnter(ev) {
                if (this.draggable) {
                    this.enterLeaveCounter++
                    this.dropEffect = ev.dataTransfer.dropEffect = !this.dropDisabled
                    && window._bTreeView !== undefined && window._bTreeView.draggedNodeKey !== undefined
                    && this.data[this.keyProp] !== window._bTreeView.draggedNodeKey
                    && (this.data[this.childrenProp] === undefined
                        || this.data[this.childrenProp].indexOf(window._bTreeView.draggedNodeData) < 0)
                    && !this.isDescendantOf(window._bTreeView.draggedNodeData)
                        ? 'move' : 'none'
                    if (this.dropEffect === 'move' && this.enterLeaveCounter === 1) {
                        this.nodeDragOver = true
                    }
                }
            },
            dragLeave() {
                if (this.draggable) {
                    this.enterLeaveCounter--
                    if (this.enterLeaveCounter !== 1) {
                        this.nodeDragOver = false
                    }
                }
            },
            // dragend(ev) {
            dragend() {
                if (this.draggable) {
                    EventBus.$off('dropOK')
                    EventBus.$off('cutOK')
                    this.dropDisabled = false
                    EventBus.$emit('nodeDragEnd')
                }
            },
            dragover(ev) {
                if (this.draggable) {
                    ev.dataTransfer.dropEffect = this.dropEffect || 'none'
                }
            },
            isDescendantOf(nodeData) {
                if (nodeData[this.childrenProp] === undefined) {
                    return false
                }
                let nodes = [
                    nodeData
                ]
                for (let i = 0; i < nodes.length; i++) {
                    let tmpNode = nodes[i]
                    if (tmpNode[this.childrenProp] !== undefined) {
                        for (let child of tmpNode[this.childrenProp]) {
                            if (child === this.data) {
                                return true
                            }
                        }
                        nodes.push(...tmpNode[this.childrenProp])
                    }
                }
            },
            draggingStarted(draggedNode) {
                if (this.draggable) {
                    this.draggedNode = draggedNode
                    this.enterLeaveCounter = 0
                    // let's listen for the drag end event
                    EventBus.$on('nodeDragEnd', this.draggingEnded)
                }
            },
            draggingEnded() {
                if (this.draggable) {
                    // stop listening for the dragging end event
                    EventBus.$off('nodeDragEnd', this.draggingEnded)
                    this.draggedNode = null
                }
            },
            dropNodeAtPosition(pos) {
                if (this.draggable) {
                    let insertAfter = pos - 1 < 0 ? null : this.data[this.childrenProp][pos - 1]
                    EventBus.$on('cutOK', () => {
                        let pos = this.data[this.childrenProp].indexOf(insertAfter) + 1
                        this.data[this.childrenProp].splice(pos, 0, window._bTreeView.draggedNodeData)
                        delete window._bTreeView.draggedNodeKey
                        delete window._bTreeView.draggedNodeData
                        EventBus.$off('cutOK')
                    })
                    EventBus.$emit('dropOK')
                }
            },
            showContextMenu(event) {
                if (this.renaming) {
                    this.cancelRenaming()
                }
                this.select()
                if(this.contextMenu) {
                    event.preventDefault();
                    EventBus.$emit('openNodeContextMenu', this)
                }
            },
            delete() {
                this.$emit('deleteNode', this)
            },
            deleteChildNode(childNodeData) {
                let children = this.data[this.childrenProp]
                let idx = children.indexOf(childNodeData)
                children.splice(idx, 1)
            },
            appendChild(childNodeData) {
                if (this.data[this.childrenProp] === undefined) {
                    Vue.set(this.data, this.childrenProp, [])
                }
                this.data[this.childrenProp].push(childNodeData)
                this.expanded = true
            },
            startRenaming() {
                this.deselect();
                this.renameNewLabel = this.data[this.labelProp]
                this.renaming = true
            },
            cancelRenaming() {
                this.renameNewLabel = this.data[this.labelProp]
                this.renaming = false
            },
            endRenaming() {
                this.data[this.labelProp] = this.renameNewLabel
                this.renaming = false
                EventBus.$emit('saveNodeItem', this.data)

            },
            dblClickLabel() {
                if (this.renameOnDblClick) {
                    this.startRenaming();
                }

            },
            /**
             * expand for selected
             */
            nodeExpand() {
                const child = this.data.children
                if (child === undefined) {
                    return false;
                }
                // this.expanded = this.expanded  || child.filter(itm => itm.selected).length >0;
                this.expanded = this.expanded  || this.data.selected || child.filter(itm => itm.selected).length >0;
                return this.expanded;
            }
        },
        created() {
            EventBus.$on('nodeDragStart', this.draggingStarted)
            this.renameNewLabel = this.data[this.labelProp]

            this.$watch(`data.${this.childrenProp}`, function (children) {
                if (children.length === 0 && this.expanded) {
                    this.expanded = false
                }
            })
            if (this.$parent) {
                this.$parent.$on('dropDisabled', () => {
                    this.dropDisabled = true
                })
                this.$parent.$on('dropEnabled', () => {
                    this.dropDisabled = false
                })
            }
        }
    }

</script>
