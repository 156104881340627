<template>
	<div data-category="type" class="pubs-search__box js-category" data-type="static_selector">
		<div class="pubs-search__category">{{tt('kind')}}:</div>
		<div v-for='item in items' :key='item.id' class="pubs-search__item">
			<input 
				type="radio" 
				class="pubs-search__tag" 
				:id="`pubs-search__item__${item.id}`" 
				:value="`${item.value}`" 
				name="publs" 
				@click="selectBtn(item)"
				v-model="publsTypeDefault" 
			>
			<label :for="`pubs-search__item__${item.id}`" class="pubs-search__item-label">{{tt(item.label)}}</label>
		</div>
		<div class="pubs-search__item">
			<label v-if="showFilter" class="filter">
				<input 
					class="filter__input filter__input--checkbox"
					type="checkbox" 
					@change="clickFilter"
					v-model="filterCheckDefault"
				>
				<span class="filter__label">{{filterLabel}}</span>
			</label>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { mixUtils } from '../mixins/mixUtils.js';

    export default {
		name: 'BtnGroupComp',
        props: {
            items: {
                type: Array,
                required: true,
                default: () => [],
			},
			publsTypeDefault: {
				type: String,
				required: true,
			},

			showFilter: {
				type: Boolean,
				required: false,
				default: false,
			},
			filterLabel: {
				type: String,
				required: false,
				default: 'filter',
			},
			filterCheckDefault: {
				type: Boolean,
				required: false,
				default: false,
			},
			t: {
				type: Object,
				required: false,
				default: () => {},
			},
			config: {
                type: Object,
                required: false,
                default: () => {},
			},

			onBtnSelected: {
				type: Function,
				required: true,
			},
			onFilterClick: {
				type: Function,
				required: true,
			},
		},
		mixins: [mixUtils,],
		methods: {
			selectBtn (item) {
				if (!item) {
					return
				}
				if (this.onBtnSelected) {
					this.onBtnSelected(item)
				}
			},
			clickFilter (e) {
				if (this.onFilterClick) {
					this.onFilterClick(e.target.checked)
				}
			},
		},
    };
</script>
