<template>
	<div id="publ-home">
		<div
			class="pubs-card pubs-card--search"
			data-mask
			data-state
			data-statekey
			data-quick
		>
			<div class="pubs-search__box pubs-search__box_container">
				<input
					class="pubs-search__input_b"
					type="text"
					:placeholder="
						ruVersion ? 'Поиск публикаций...' : 'Search...'
					"
					v-model="searchText"
					@keyup.enter="searchStart(1)"
				/>
				<button class="pubs-search__button" @click="searchStart(1)">
					{{ tt("search") }}
				</button>
			</div>
			<btn-group
				v-show="showBtnGroup"
				:items="btnGroupItems"
				:publsTypeDefault="btnGroupSelected"
				:showFilter="true"
				:filterLabel="tt('openAccessDoc')"
				:filterCheckDefault="fullTextPublicEnabled"
				:t="t"
				:onBtnSelected="onBtnGroupSelected"
				:onFilterClick="onFilterClick"
				:config="config"
			></btn-group>
			<dynamic-criteria
				:ruVersion="ruVersion"
				:t="t"
				:config="config"
				:queryParams="queryParams"
				:onSelectCategory="onSelectCategory"
			></dynamic-criteria>
		</div>
		<search-result
			v-if="!showVertelka"
			:itemList="itemList"
			:totalFoundPubls="totalFoundPubls"
			:totalFoundPages="totalFoundPages"
			:currentPageId="currentPage"
			:t="t"
			:config="config"
			:ruVersion="ruVersion"
			:sortBtns="sortBtns"
			:sortValue="currSortValue"
			:showCover="showCover"
			:showFoundPublications="showFoundPublications"
			:onSortBtnClick="onSortBtnClick"
			:onClickPageBtn="onClickPageBtn"
		></search-result>
		<div v-if="showVertelka" class="pubs-show-loader">
			<div class="pubs-loading"></div>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import axios from "axios";
	import BtnGroupComp from "./components/btnGroupComp.vue";
	import DynamicCriteriaComp from "./components/dynamicCriteriaComp.vue";
	import SearchResultComp from "./components/searchResultComp.vue";
	import { mixDataLoader } from "./mixins/mixDataLoader.js";
	import { mixUtils } from "./mixins/mixUtils.js";
// import { existentialTypeParam } from "babel-types";
	export default {
		name: "Search",
		components: {
			"btn-group": BtnGroupComp,
			"dynamic-criteria": DynamicCriteriaComp,
			"search-result": SearchResultComp,
		},
		data: () => ({
			searchText: "",
			sliderTitle: "Поиск",
			btnGroupItems: [
				{ id: 0, value: "ANY", label: "any", savedParams: "BibitemAny" },
				{
					id: 1,
					value: "BOOK",
					label: "books",
					savedParams: "BibitemBook",
				},
				{
					id: 2,
					value: "CHAPTER",
					label: "chapters",
					savedParams: "BibitemChapter",
				},
				{
					id: 3,
					value: "ARTICLE",
					label: "articles",
					savedParams: "BibitemArticle",
				},
				{
					id: 4,
					value: "PREPRINT",
					label: "preprints",
					savedParams: "BibitemPreprint",
				},
			],
			sortBtns: [
                {id: 0, value: 'TITLE', range: 'ASC', label: 'byName', ruLabel: 'по названию', enLabel: 'by name'},
                {id: 1, value: 'YEAR', range: 'DESC', label: 'byYear', ruLabel: 'по году', enLabel: 'by year'},
            ],
			btnGroupSelected: "ANY",
			fullTextPublicEnabled: false,
			selectCategory: [],
			queryParams: [],
			itemList: [],
			showBtnGroup: true,
			changeSearchUrl: true, // нужно ли менять URL на "search?" по завершению поиска
			orderBy: ['TITLE_ASC'],
			totalFoundPubls: 0,
			totalFoundPages: 0,
			publPerPage: 21,
			// lastIdInPage: 0,
			showCover: false,
			searchAutoRun: false,
			priselectedAutoRun: false,
			showFoundPublications: false,
			showVertelka: false,
			mainTitle: "",
			currentPage: 1,
			nn: 0,
			currSortValue: 'TITLE',
		}),
		metaInfo() {
			return {
				title: this.mainTitle,
				lang: this.ruVersion ? "ru" : "en",
			};
		},

		mixins: [mixDataLoader, mixUtils],
		computed: mapState({
			t: (state) => state.templateT,
			ruVersion: (state) => state.templateRuVersion,
			config: (state) => state.config,
		}),

		methods: {
			async searchStart(currentPageNumber) {
				this.currentPage = currentPageNumber;
				let filter = this.searchText ? `"search":"${this.searchText}"` : "";
				// console.log(`>>> searchStart filter: ${JSON.stringify(filter, null, 4)}`)
				// console.log(`>>> searchStart this.selectCategory: ${JSON.stringify(this.selectCategory, null, 4)}`)
				for (const item of this.selectCategory) {
					filter +=
						item.type == "author" && item.value.length
							? `${
									filter.length === 0 ? "" : "|"
							  }"authors":${JSON.stringify(
									item.value.map((itm) => {
										return { id: itm.id, title: itm.title };
									})
							  )}`
							: "";
					filter +=
						item.type == "campus" && item.value !== "0"
							? `${filter.length === 0 ? "" : "|"}"campus":[${
									Array.isArray(item.value)
										? item.value.split(",")
										: item.value
							  }]`
							: "";
					filter +=
						item.type == "unit" && item.value.length
							? `${filter.length === 0 ? "" : "|"}${
									item.checked ? '"childDeparts"' : '"units"'
							  }:${JSON.stringify(
									item.value.map((itm) => {
										return { id: itm.id, title: itm.title };
									})
							  )}`
							: "";
					filter +=
						item.type == "keyword" && item.value.length
							? `${
									filter.length === 0 ? "" : "|"
							  }"keywords":${JSON.stringify(
									item.value.map((itm) => {
										return { id: itm.id, title: itm.title };
									})
							  )}`
							: "";
					filter +=
						item.type == "researchTarget" && item.value.length
							? `${
									filter.length === 0 ? "" : "|"
							  }"researchTargets":${JSON.stringify(
									item.value.map((itm) => {
										return { id: itm.id, title: itm.title };
									})
							  )}`
							: "";
					filter +=
						item.type == "devLine" && item.value.length
							? `${
									filter.length === 0 ? "" : "|"
							  }"priorityTargets":${JSON.stringify(
									item.value.map((itm) => {
										return { id: itm.id, title: itm.title };
									})
							  )}`
							: "";
					filter +=
						item.type == "language" && item.value.length
							? `${
									filter.length === 0 ? "" : "|"
							  }"languages":${JSON.stringify(
									item.value.map((itm) => {
										return { id: itm.id, title: itm.title };
									})
							  )}`
							: "";
					filter +=
						item.type == "year" && item.value.length
							? `${
									filter.length === 0 ? "" : "|"
							  }"yearsRange":${JSON.stringify(item.value)}`
							: "";
					filter +=
						item.type == "pb" && item.value !== "0"
							? `${filter.length === 0 ? "" : "|"}"publisher":[${
									item.value
							  }]`
							: "";
					filter +=
						item.type == "mg" && item.value !== "0"
							? `${filter.length === 0 ? "" : "|"}"magazine":[${
									item.value
							  }]`
							: "";
					filter +=
						item.type == "sr" && item.value !== "0"
							? `${filter.length === 0 ? "" : "|"}"orgseries":[${
									item.value
							  }]`
							: "";
				}
				filter += `${filter.length === 0 ? "" : "|"}"acceptLanguage":${
					this.ruVersion ? '"ru"' : '"en"'
				}`;
				filter += `${
					this.fullTextPublicEnabled ? '|"fullTextPublicEnabled": 1' : ""
				}`;
				const params = {
					type: this.btnGroupSelected ? this.btnGroupSelected : "ANY",
					filterParams: filter,
					paginationParams: {
						publsSort: this.orderBy,
						publsCount: this.publPerPage,
						pageId: currentPageNumber,
					},
				};
				// console.log(`>>> params: ${JSON.stringify(params, '', 4)}`);
				this.totalFoundPubls = 0;
				this.totalFoundPages = 0;
				//
				this.showVertelka = true;
				this.itemList = await axios
					.post(`${this.getPrefixURL()}api/searchPubs`, params)
					.then((r) => {
						const items = [];
						if (r.data.status == "ok") {
							this.totalFoundPubls = r.data.result.total;
							this.totalFoundPages = Math.ceil(
								this.totalFoundPubls / this.publPerPage
							);
							for (const item of r.data.result.items) {
								if (item.title) {
									// this.lastIdInPage = item.id;
									items.push(item);
								}
							}
							if (this.changeSearchUrl) {
								this.$router
								.push(`${this.getPrefixURLLang()}search?search=${r.data.cachKey}&quick=${this.searchText}`)
								.catch(() => null);
							}
						}
						//
						return items;
					});
				this.showFoundPublications = true;
				this.showVertelka = false;
			},
			onBtnGroupSelected(item) {
				this.btnGroupSelected = item.value;
			},
			onFilterClick(val) {
				this.fullTextPublicEnabled = val;
			},

			checkCriteria(criteria) {
				return false;
			},
			async onSelectCategory(categories, changeSearchHash) {
				//
				this.selectCategory = categories.filter((itm) => {
					if (!itm.show && itm.value !== "0") {
						if (itm.id === 2 &&	itm.value === "22723,135083,135213,135288") { // Campus != "все кампусы"
							return false;
						}
						return true;
					}
					return false;
				});
				if (this.searchAutoRun && this.selectCategory.length > 0) {
					this.changeSearchUrl = changeSearchHash;
					await this.searchStart(1);
					this.searchAutoRun = false;
					this.changeSearchUrl = true;
				}
			},
			priselectedParams() {
				if (this.$router && this.$router.currentRoute) {
					const path = this.$router.currentRoute.path;
					const query = this.$router.currentRoute.query;
					let filterParams = "";
					// Тематики/Рубрики
					if (path.split("/").includes("rubric")) {
						const id = Number(this.$router.currentRoute.params.id.replace(/\D+/g,""));
						if (Number.isInteger(id)) {
							filterParams = `researchTargets: ${id} `;
							this.searchParams = `?research_target=${id}`;
							this.priselectedAutoRun = true;
						}
					}
					// Реакция на /publications/articles
					if (path.split("/").includes("articles")) {
						this.btnGroupSelected = "ARTICLE";
						this.showBtnGroup = false;
						this.changeSearchUrl = query && query.mg; //false;
						this.$store.dispatch("aChangeSelectPrimaryMenu", 1);
						this.mainTitle = this.ruVersion ? "Статьи - Публикации ВШЭ" : "Articles - Publications of HSE";
						this.priselectedAutoRun = true;
					}
					// Реакция на /publications/books
					if (path.split("/").includes("books")) {
						this.btnGroupSelected = "BOOK";
						this.showBtnGroup = false;
						this.changeSearchUrl = query && query.pb; // false`
						this.showCover = true;
						this.$store.dispatch("aChangeSelectPrimaryMenu", 0);
						this.mainTitle = this.ruVersion ? "Книги - Публикации ВШЭ" : "Books - Publications of HSE";
						this.priselectedAutoRun = true;
					}
					// Реакция на /publications/chapters
					if (path.split("/").includes("chapters")) {
						this.btnGroupSelected = "CHAPTER";
						this.showBtnGroup = false;
						this.changeSearchUrl = false;
						this.$store.dispatch("aChangeSelectPrimaryMenu", 2);
						this.mainTitle = this.ruVersion ? "Главы в книгах - Публикации ВШЭ"	: "Book chapters - Publications of HSE";
						this.priselectedAutoRun = true;
					}
					// Реакция на /publications/preprints
					if (path.split("/").includes("preprints")) {
						this.btnGroupSelected = "PREPRINT";
						this.showBtnGroup = false;
						this.changeSearchUrl = query && query.sr; //false;
						this.$store.dispatch("aChangeSelectPrimaryMenu", 3);
						this.mainTitle = this.ruVersion ? "Препринты - Публикации ВШЭ" : "Working papers - Publications of HSE";
					}
					this.priselectedAutoRun = true;
					// Реакция на /publications/search
					if (path.split("/").includes("search")) {
						this.btnGroupSelected = "ANY";
						this.showBtnGroup = true;
						this.changeSearchUrl = true;
						this.mainTitle = this.ruVersion	? "Поиск - Публикации ВШЭ"	: "Search - Publications of HSE";
						this.priselectedAutoRun = query && query.ou; // false;
					}
				}
			},
			getPublsList(baseURL) {
				return this.$store.dispatch("aLoadTemplateData", { baseURL });
			},
			onSortBtnClick(btn) {
				const sortBtn = this.sortBtns.filter(el=>el.id===btn.id)[0];
                sortBtn.range = (sortBtn.range === 'ASC') ? 'DESC' : 'ASC';
				this.currSortValue = sortBtn.value;
				this.orderBy = this.sortChanger(sortBtn);
				this.searchStart(1);
			},
			sortChanger(sortBtn) {
				return sortBtn.value === 'YEAR' ? [`${sortBtn.value}_${sortBtn.range}`] : [`${sortBtn.value}_${sortBtn.range}`];
			},
			/**
			 * Обработчик сохранённых параметров. Сортировка 
			 * @param {"field":"title","direction":1} sortObj 
			 */
			spSort(sortObj){
				// console.log(`>>> sortObj: ${JSON.stringify(sortObj)}`)
				if (sortObj && Array.isArray(sortObj)) {
					return (sortObj[0].field.toLowerCase() === 'year')
						? [`YEAR_${sortObj.direction == 1 ? 'ASC' : 'DESC'}`]
						: [`TITLE_${sortObj.direction == 1 ? 'ASC' : 'DESC'}`];
				} else if (sortObj && !Array.isArray(sortObj)) {
					return (sortObj.field.toLowerCase() === 'year')
						? [`YEAR_${sortObj.direction == 1 ? 'ASC' : 'DESC'}`]
						: [`TITLE_${sortObj.direction == 1 ? 'ASC' : 'DESC'}`];
				} else {
					return ['TITLE_ASC']
				}
			},
			onClickPageBtn(page) {
				this.searchStart(page);
			},
			async mody() {
				this.searchAutoRun = false;
				this.priselectedParams();
				this.queryParams.length = 0;

				if(!this.$route.query) return;
				const query = this.$route.query;
				if (query.dl) {
					this.queryParams.push({ type: "dl", id: query.dl, title: "" });
					this.btnGroupSelected = "ANY";
				} else if (query.kw) {
					this.queryParams.push({ type: "kw", id: query.kw, title: "" });
					this.btnGroupSelected = "ANY";
				} else if (query.pb) {
					// для поиска издательств в книгах
					this.queryParams.push({ type: "pb", id: query.pb, title: "" });
					this.btnGroupSelected = "ANY";
				} else if (query.mg) {
					// для поиска журналов в статьях
					this.queryParams.push({ type: "mg", id: query.mg, title: "" });
					this.btnGroupSelected = "ANY";
				} else if (query.sr) {
					// для поиска серии в препринтах
					this.queryParams.push({ type: "sr", id: query.sr, title: "" });
					this.btnGroupSelected = "ANY";
				} else if (query.ou) {
					// для поиска в подразделениях
					this.queryParams.push({type: "ou", id: query.ou, title: "" });
					this.btnGroupSelected = "ANY";
				} else if (query.lang) {
					this.queryParams.push({
						type: "lang",
						id: 0,
						title: query.lang,
					});
					this.btnGroupSelected = "ANY";
				} else if (query.year) {
					this.queryParams.push({
						type: "year",
						id: query.year,
						title: "",
					});
					this.btnGroupSelected = "ANY";
				} else if (query.rubric) {
					this.queryParams.push({
						type: "rubric",
						id: Number(query.rubric.replace(/\D+/g,"")),
						title: "",
					});
					this.btnGroupSelected = "ANY";
				} else if (query.search) {
					console.log(`>>> query.search: ${JSON.stringify(query.search, null, 4)}`)

					this.searchText = query.hasOwnProperty("quick")	? query.quick : "";
					this.priselectedAutoRun = this.searchText.length > 0;
					const savedParams = await axios.post(`api/savedCriteria`, {key: query.search})
						.then((r) => {
							if (r.data.status == "ok" && r.data.result.json) {
								return r.data.result.json.state;
							}
							return null;
						})
						.catch((err) => {
							return null;
						});
					if (savedParams.sort) {
						this.orderBy = this.spSort(savedParams.sort);
						this.currSortValue = Array.isArray(savedParams.sort) ? savedParams.sort[0].field.toUpperCase() : savedParams.sort[0].field.toUpperCase();
						const sortBtn = this.sortBtns.filter(el=>el.value===this.currSortValue)[0];
						if (sortBtn) {
							this.sortBtns[sortBtn.id].range = Array.isArray(savedParams.sort) ? (savedParams.sort[0].direction === 1 ? 'ASC' : 'DESC') : (savedParams.sort.direction === 1 ? 'ASC' : 'DESC');
							this.priselectedAutoRun = true;
						}
					}
					if (savedParams.type) {
						//"type":"BibitemBook"
						const resType = this.btnGroupItems.filter((itm) => {
							return itm.savedParams == savedParams.type;
						});
						this.btnGroupSelected =	resType.length > 0 ? resType[0].value : "ANY";
						this.priselectedAutoRun = resType.length > 0;
					}
					if (savedParams.unit && savedParams.unit.ids.length) {
						// "unit":{"ids":[195260597],"values":["Проектная лаборатория по изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв."]}
						this.queryParams.push({
							type: "unit",
							id: savedParams.unit.ids,
							title: savedParams.unit.values,
						});
					}
					if ((savedParams.childDeparts && savedParams.childDeparts.ids.length) || (savedParams.childDepart && savedParams.childDepart.ids.length)) {
						// "unit":{"ids":[195260597],"values":["Проектная лаборатория по изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв."]}
						this.queryParams.push({
							type: "childDeparts",
							id:	savedParams?.childDeparts?.ids || savedParams?.childDepart?.ids,
							title: savedParams?.childDeparts?.values ||	savedParams?.childDepart?.values,
						});
					}
					if ((savedParams.keywords && savedParams.keywords.ids.length) || (savedParams.keyword && savedParams.keyword.ids.length)) {
						//  {"ui": 1, "keyword": {"ids": [144499588], "values": ["философский анализ института президентства"]}}
						this.queryParams.push({
							type: "keyword",
							id: savedParams?.keywords?.ids || savedParams?.keyword?.ids,
							title: savedParams?.keywords?.values ||	savedParams?.keyword?.values,
						});
					}
					if ((savedParams.authors && savedParams.authors.ids.length) || (savedParams.author && savedParams.author.ids.length)) {
						//  {"ui": 1, "sort": {"field": "year", "direction": 1}, "author": {"ids": [35436393], "values": ["Кряжков Владимир Алексеевич"]}, "recursive": "recursive", "doc_access": "public"}}
						this.queryParams.push({
							type: "author",
							id:	savedParams?.authors?.ids || savedParams?.author?.ids,
							title: savedParams?.authors?.values || savedParams?.author?.values,
						});
					}
					if (savedParams.devline && savedParams.devline.ids.length) {
						this.queryParams.push({
							type: "devLine",
							id: savedParams.devline.ids,
							title: savedParams.devline.values,
						});
					}
					if (savedParams.priorityTargets && savedParams.priorityTargets.ids.length) {
						this.queryParams.push({
							type: "devLine",
							id: savedParams.priorityTargets.ids,
							title: savedParams.priorityTargets.values,
						});
					}
					if (savedParams.campus) {
						//  {"ui": 1, "sort": {"field": "year", "direction": 1}, "campus": 22723,
						this.queryParams.push({
							type: "campus",
							id: savedParams.campus,
							title: "",
						});
					}
					if (savedParams.year) {
						//  {"ui": 1, "sort": {"field": "title", "direction": 1}, "type": "BibitemArticle", "year": {"max": 2019, "min": 2013},
						this.queryParams.push({
							type: "year",
							id: savedParams.year,
							title: "",
						});
					}
					if ((savedParams.language && savedParams.language.ids.length) || (savedParams.languages && savedParams.languages.ids.length)) {
						// 	//  {"ui": 1, "sort": {"field": "title", "direction": 1}, "type": "BibitemArticle", "language": {"ids": [20591], "values": ["русский"]},
						//  {"ui": 1, "sort": {"field": "title", "direction": 1}, "type": "BibitemArticle", "languages": {"ids": [20591], "values": ["русский"]},
						this.queryParams.push({
							type: "language",
							id:	savedParams?.language?.ids || savedParams?.languages?.ids,
							title: savedParams?.language?.values ||	savedParams?.languages?.values,
						});
					}
					if (savedParams.research_target && savedParams.research_target.ids.length) {
						//  {"ui": 1, "research_target": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
						this.queryParams.push({
							type: "researchTarget",
							id: savedParams.research_target.ids,
							title: savedParams.research_target.values,
						});
					}
					if (savedParams.researchTargets && savedParams.researchTargets.ids.length) {
						//  {"ui": 1, "researchTargets": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
						this.queryParams.push({
							type: "researchTarget",
							id: savedParams.researchTargets.ids,
							title: savedParams.researchTargets.values,
						});
					}
					if (savedParams.pb && savedParams.pb.ids.length) {
						//  {"ui": 1, "pb": {"ids": [218116772], "values": [""]}}}
						this.queryParams.push({
							type: "pb",
							id: savedParams.pb.ids,
							title: savedParams.pb.values,
						});
					}
					if (savedParams.mg && savedParams.mg.ids.length) {
						//  {"ui": 1, "mg": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
						this.queryParams.push({
							type: "mg",
							id: savedParams.mg.ids,
							title: savedParams.mg.values,
						});
					}
					if (savedParams.sr && savedParams.sr.ids.length) {
						//  {"ui": 1, "sr": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
						this.queryParams.push({
							type: "sr",
							id: savedParams.sr.ids,
							title: savedParams.sr.values,
						});
					}
				}
				this.searchAutoRun = Boolean(this.queryParams.length > 0);
			},
		},

		/**
		 * Сервер. Первичное гидрирование
		 */
		serverPrefetch() {
			this.$store.dispatch("aLoadConfig", {
				config: this.$ssrContext.config,
			});
			return this.getPublsList(this.config.serverBaseURL);
		},
		/**
		 * Клиент. Доп. загрузка
		 */
		async mounted() {
			this.nn = 0;
			await this.mody();
			if (!this.t) {
				await this.getPublsList(this.config.clientBaseURL);
			}

			if (this.priselectedAutoRun && this.queryParams.length == 0) {
				const page = this.$route.params && this.$route.params.pg && this.$route.params.pg > 0 ? Number(this.$route.params.pg) : 1;
				await this.searchStart(page);
				this.priselectedAutoRun = false;
			}
			if (this.changeSearchUrl) {
				this.$store.dispatch("aChangeSelectSecongaryMenu", 1);
			}
		},
	};
</script>