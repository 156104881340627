import { createApp } from './app';
import './assets/style.scss';

const { app, router, store } = createApp({ state: window.__INITIAL_STATE__ });
// const { app, router, store} = createApp();

router.onReady(() => {
	const currScr = getCurrentScript();
	const appMultiId = currScr && currScr.dataset && currScr.dataset.orgunitId || currScr && currScr.dataset && currScr.dataset.author ||null;
	if (currScr && appMultiId) {
		const el = document.createElement('div');
		el.id = `app_${appMultiId}`;
		el.classList.add('pb-preloader');
		currScr.parentNode.replaceChild(el, currScr);
	}
	if (window.__INITIAL_STATE__) {
		store.replaceState(window.__INITIAL_STATE__);
	}
	appMultiId ? app.$mount(`#app_${appMultiId}`) : app.$mount('#app');
});

function getCurrentScript() {
	const currentScript = document.currentScript;
	if (currentScript) {
		return currentScript;
	} else {
		const scripts = document.getElementsByTagName('script');
		currentScript = scripts ? scripts[scripts.length - 1] : null;
	}
	return currentScript;
}
