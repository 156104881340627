import Vue from 'vue';
import Vuex from 'vuex';
import Meta from 'vue-meta'
import App from './App.vue';
import createRouter  from './router';
import createStore   from './store/publStore';
import { sync } from 'vuex-router-sync';

Vue.use(Vuex);
Vue.use(Meta, {
    // ssrAppId: 'ssr',
    keyName: 'metaInfo',
    attribute: 'dvm',
    ssrAttribute: 'dvms',
    tagIDKeyName: 'id',
    refreshOnceOnNavigation: true
});

export const createApp = (context) =>  {
    const router = createRouter(context);
    const store = createStore();
    sync(store, router)
    const app = new Vue({
        store,
        router,
        render: h => h(App),
    });
    return {app, router, store};
};