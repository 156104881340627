<template>
	<div id="widget-pubs" >
		<template v-if="config.widgetName === 'Search'">
			<search-result
				v-if="!showVertelka"
				:itemList="itemList"
				:totalFoundPubls="totalFoundPubls"
				:totalFoundPages="totalFoundPages"
				:currentPageId="currentPage"
				:t="t"
				:config="config"
				:ruVersion="ruVersion"
				:sortBtns="sortBtns"
				:sortValue="currSortValue"
				:showSortBtn="!(widgetOperParams && widgetOperParams.ismanual && !widgetOperParams.showallatpublicationspage)"
				:showCover="showCover"
				:showFoundPublications="showFoundPublications"
				:onSortBtnClick="onSortBtnClick"
				:onClickPageBtn="onClickPageBtn"
			></search-result>
			<div v-if="showVertelka" class="pubs-show-loader">
				<div class="pubs-loading"></div>
			</div>
		</template>
		<template v-if="config.widgetName==='PubsMarked'">
			<div class="fa-card fa-card--publications side_bar_width">
				<div class="fa-card__content small">
					<div class="fa-card__caption">
						<a class="link link_dark2 link_no-underline no-visited" :href="`${getPrefixURLLang()}publications/`">{{ruVersion ? 'Публикации':'Publications'}}</a>
					</div>
					<div v-if="itemList && itemList.length && !showVertelka">
						<div v-for="itm in itemList" :key="itm.id" class="b-publications-inst__item">
							<div class="extraEvTitle-holder extraEvTitle-holder_left b-publications-inst__item-type">
								<div class="extraEvTitle grey">{{titleChoice(itm.type || '', ruVersion)}}</div>
							</div>
							<a :href="`${getPrefixPubsURLLang()}view/${itm.id || 0}`" target="_blank" class="link link_no-underline b b-publications-inst__title">
								<span class="link">{{itm.title || ''}}</span>
							</a>
							<p class="b">
								<span v-for='(author, ind ) in itm && itm.authorsByType && itm.authorsByType.author ? itm.authorsByType.author : []' :key='author.id' >
									<template v-if="ind < 3">
										<span v-if="ind > 0" class="nowrap">, </span>
										<a v-if="author.href && (!ruVersion && author.enVersionStatus != 2 ? false : true )" class="link" target="_blank" :href="`${!ruVersion ? '/en' : ''}${author.href}`">{{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</a>
										<span v-else class="nowrap pubs-author"> {{author && author.altName ? author.altName : (author && author.otherName ? author.otherName :  (author && author.title ? langChoice(author.title) : ''))}}</span>
									</template>
									<span v-else-if="ind == 3"> {{ruVersion ? 'и др.' : 'et al.'}}</span>
								</span>
							</p>
							<p class="source italic">
								{{itm && itm.description && itm.description.short.publ ? itm.description.short.publ : ''}}.
							</p>
						</div>
					</div> 
					<div v-if="showVertelka" class="pubs-show-loader">
						<div class="pubs-loading"></div>
					</div>
					<div class="tag-set" style="margin-top: 1em;">
						<a class="tag large" :href="`${urlById}publications`" :title="`${ruVersion ? 'Все публикации':'All publications'}`">{{ruVersion ? 'Все публикации':'All publications'}}</a>
					</div>
				</div>
			</div>
		</template>
		<template v-if="config.widgetName === 'AuthorSearch'">
			
			<search-result-list
				v-if="!showVertelka"
				:itemList="itemList"
				:totalFoundPubls="totalFoundPubls"
				:pubsPersonGroupType="pubsPersonGroupType"
				:pubsPersonSort="pubsPersonSort"
				:t="t"
				:config="config"
				:ruVersion="ruVersion"
				:sortBtns="sortBtns"
				:sortValue="currSortValue"
				:admComment="admComment"
				:showSortBtn=false
				:showPubsHeader="showPubsHeader"
				:showTotalPubls="showTotalPubls"
				:showCover="showCover"
				:showFoundPublications="showFoundPublications"
				:onSortBtnClick="onSortBtnClick"
				:onClickPageBtn="onClickPageBtn"
			></search-result-list>
			<div v-if="showVertelka" class="pubs-show-loader">
				<div class="pubs-loading"></div>
			</div>

			
			
			<!-- <h3>{{ruVersion ? 'Публикации':'Publications'}}<sup>{{totalFoundPubls}}</sup></h3>
			<ul class="g-ul g-list g-list_closer publications">
				<li>
					<div class=" large">
						<span class="i">Книга</span> 
						<span class="nowrap"><a target="_blank" href="//p1.hse.ru/en/org/persons/203698" class="link">Abdrakhmanova G.</a></span>, 
						<span class="nowrap"><a target="_blank" href="//p1.hse.ru/en/org/persons/126871719" class="link">Vasilkovsky S.</a></span>, 
						<i><a target="_blank" href="https://publications.hse.ru/view/553800053" class="link">Digital economy: 2022 : Pocket data book</a></i> --> 

		</template>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import axios from "axios";
	import SearchResultComp from "../components/searchResultComp.vue";
	import SearchResultListComp from "../components/searchResultListComp.vue";
	import { mixDataLoader } from "../mixins/mixDataLoader.js";
	import { mixUtils } from "../mixins/mixUtils.js";
	export default {
		name: "Search",
		components: {
			"search-result": SearchResultComp,
			"search-result-list": SearchResultListComp,
		},
		data: () => ({
			searchText: "",
			fullTextPublicEnabled: false,
			selectCategory: [
				{id: 1, type: 'author', value: null, defaultValue: [{id: null, title: null}]},
				{id: 2, type: 'campus', value: '0', defaultValue: []},
				{id: 3, type: 'unit', value: null, checked: false},
				{id: 4, type: 'keyword', value: null},
				{id: 5, type: 'researchTarget', value: null},
				{id: 6, type: 'devLine', value: null},
				{id: 7, type: 'language', value: null},
				{id: 8, type: 'year',value: [], defaultValue: [0,0]},
				{id: 10, type: 'pb', value: '0'},
				{id: 11, type: 'mg', value:'0'},
				{id: 12, type: 'sr', value: '0'},
			],
			sortBtns: [
                {id: 0, value: 'TITLE', range: 'ASC', label: 'byName', ruLabel: 'по названию', enLabel: 'by name'},
                {id: 1, value: 'YEAR', range: 'DESC', label: 'byYear', ruLabel: 'по году', enLabel: 'by year'},            ],
			currSortValue: 'YEAR',
			queryParams: [],
			orderBy: ["YEAR_DESC", "TITLE_ASC"],
			publPerPage: 21,
			showCover: false,
			showSortBtn: true,
			searchAutoRun: false,
			showFoundPublications: true,
			showVertelka: true,
			mainTitle: "",
			currentPage: 1,
			changeSearchUrl: false,
			showTotalPubls: false,
			showPubsHeader: true
		}),

		mixins: [mixDataLoader, mixUtils],
		computed: mapState({
			t: (state) => state.templateT,
			ruVersion: (state) => state.templateRuVersion,
			config: (state) => state.config,
			itemList: (state) => state.itemList,
			totalFoundPubls: (state) => state.totalFoundPubls,
			totalFoundPages: (state) => state.totalFoundPages,
			cachKey: (state) => state.cachKey,
			urlById: (state) => state.urlById,
			widgetOperParams: (state) => state.widgetOperParams,
			pubsPersonGroupType: (state) => state.pubsPersonGroupType,
			pubsPersonSort: (state) => state.pubsPersonSort,
			admComment: (state) => state.admComment,
		}),

		methods: {
			searchWidgetStart(baseURL, currentPageNumber) {
				// console.log(`>>> searchStart started!`)
				this.showFoundPublications = false;
				this.showVertelka = true;
				this.currentPage = currentPageNumber === 0 ? 1 : currentPageNumber;
				let filter = this.searchText ? `"search":"${this.searchText}"` : "";
				for (const item of this.selectCategory) {
					const devider = filter.length === 0 ? "" : "|";
					filter += item.type == "author" && item.value ? `${devider}"authors":${JSON.stringify(item.value.map(itm => {id: itm.id; title: itm.title;}))}` : "";
					filter += item.type == "campus" && item.value !== "0" ? `${devider}"campus":[${Array.isArray(item.value) ? item.value.split(",") : item.value}]` : "";
					filter += item.type == "unit" && item.value ? `${devider}${item.checked ? '"childDeparts"' : '"units"'}:${JSON.stringify(item.value.map(itm => {id: itm.id; title: itm.title;}))}` : "";
					filter += item.type == "keyword" && item.value ? `${devider}"keywords":${JSON.stringify(item.value.map(itm => { id: itm.id; title: itm.title;}))}` : "";
					filter += item.type == "researchTarget" && item.value ? `${devider}"researchTargets":${JSON.stringify(item.value.map(itm => {id: itm.id; title: itm.title;}))}`	: "";
					filter += item.type == "devLine" && item.value ? `${devider}"priorityTargets":${JSON.stringify(item.value.map(itm => { id: itm.id; title: itm.title; }))}` : "";
					filter += item.type == "language" && item.value ? `${devider}"languages":${JSON.stringify(item.value.map(itm => {id: itm.id; title: itm.title;}))}` : "";
					filter += item.type == "year" && item.value.length ? `${devider}"yearsRange":${JSON.stringify(item.value)}` : "";
					filter += item.type == "pb" && item.value !== "0" ? `${devider}"publisher":[${item.value}]`: "";
					filter += item.type == "mg" && item.value !== "0" ? `${devider}"magazine":[${item.value}]` : "";
					filter += item.type == "sr" && item.value !== "0" ? `${devider}"orgseries":[${item.value}]` : "";
				}
				filter += `${filter.length === 0 ? "" : "|"}"acceptLanguage":${this.ruVersion ? '"ru"' : '"en"'}`;
				filter += `${this.fullTextPublicEnabled ? '|"fullTextPublicEnabled": 1' : ""}`;
				filter += this.config.pubsUnit ? `|"pubsUnit": ${this.config.pubsUnit}` : '';
				filter += this.config.author ? `|"pubsAuthor": ${this.config.author}` : '';
				filter += this.config.widgetName ? `|"widgetName": "${this.config.widgetName}"` : '';
				const params = {
					type: "ANY",
					filterParams: filter,
					paginationParams: {
						publsSort: this.config.widgetName === 'PubsMarked' ?  this.config.publsSort : this.orderBy,
						publsCount: this.publPerPage,
						pageId: this.currentPage,
					},
				};
				// console.log(`>>> params: ${JSON.stringify(params, '', 4)}`);
				// console.log(`>>> url: ${baseURL}${this.getPrefixURL()}api/searchPubs`);
				// console.log(`>>> this.config: ${JSON.stringify(this.config, null, 4)}`)
				return this.$store.dispatch("aSearchPubs", { baseURL, params })
					.then(() => {
						// Использование SavedCriteria пока отключен. Причина: поиск не должен менять url
						if (this.changeSearchUrl) {
							this.$router
							.push(`${this.getPrefixURLLang()}search?search=${r.data.cachKey}&quick=${this.searchText}`)
							.catch(() => null);
						}
						this.showFoundPublications = true;
						this.showVertelka = false;
						if (this.config.widgetName==='AuthorSearch') {
							this.showTotalPubls = [0].includes(this.pubsPersonGroupType)
						}	
					});
			},
			
			onFilterClick(val) {
				// this.filterChecked = val;
				this.fullTextPublicEnabled = val;
			},

			checkCriteria(criteria) {
				return false;
			},
			onSortBtnClick(btn) {
				const sortBtn = this.sortBtns.filter(el=>el.id===btn.id)[0];
                sortBtn.range = (sortBtn.range === 'ASC') ? 'DESC' : 'ASC';
				this.currSortValue = sortBtn.value;
				this.orderBy = this.sortChanger(sortBtn);
				this.searchWidgetStart(this.config.clientBaseURL, 0);
			},
			onClickPageBtn(page) {
				this.searchWidgetStart(this.config.clientBaseURL, page);
			},
			sortChanger(sortBtn) {
				return sortBtn.value === 'YEAR' ? [`${sortBtn.value}_${sortBtn.range}`, "TITLE_ASC"] : [`${sortBtn.value}_${sortBtn.range}`];
			},
			/**
			 * Обработчик сохранённых параметров. Сортировка 
			 * @param [{"field":"title","direction":1}, {}] sortObj 
			 */
			spSort(sortObj){
				// console.log(`>>> sortObj: ${JSON.stringify(sortObj)}`)
				if (sortObj && Array.isArray(sortObj)) {
					return (sortObj[0].field.toLowerCase() === 'year')
						? [`YEAR_${sortObj.direction == 1 ? 'ASC' : 'DESC'}, TITLE_ASC`]
						: [`TITLE_${sortObj.direction == 1 ? 'ASC' : 'DESC'}`];
				} else if (sortObj && !Array.isArray(sortObj)) {
					return (sortObj.field.toLowerCase() === 'year')
						? [`YEAR_${sortObj.direction == 1 ? 'ASC' : 'DESC'}, TITLE_ASC`]
						: [`TITLE_${sortObj.direction == 1 ? 'ASC' : 'DESC'}`];
				} else {
					return ['TITLE_ASC']
				}
			},
			mody(plsm) {
				this.queryParams.length = 0;
				this.searchAutoRun = false;
				if(!this.$route.query || !this.$route.query.search) return null;
				const query = this.$route.query;
				// Использование SavedCriteria пока отключен. Причина: поиск не должен менять url + query
				// console.log(`>>> mody query: ${JSON.stringify(query)}`)
				this.searchText =  "";
				return axios.post(`${plsm}${this.getPrefixURL()}api/savedCriteria`, {key: query.search})
					.then((r) => {
						if (r.data.status == "ok" && r.data.result.json) {
							const savedParams = r.data.result.json.state;
							// console.log(`>>> mody savedParams: ${JSON.stringify(savedParams)}`)
							if (!savedParams) return null;
							if (savedParams.sort) {
								this.orderBy = this.spSort(savedParams.sort); // "sort":[{"field":"title","direction":1}],
								this.currSortValue = Array.isArray(savedParams.sort) ? savedParams.sort[0].field.toUpperCase() : savedParams.sort.field.toUpperCase();
								const sortBtn = this.sortBtns.filter(el=>el.value===this.currSortValue)[0];
								if (sortBtn) {
									this.sortBtns[sortBtn.id].range = Array.isArray(savedParams.sort) ? (savedParams.sort[0].direction === 1 ? 'ASC' : 'DESC') : (savedParams.sort.direction === 1 ? 'ASC' : 'DESC');
								}
							}
							if (savedParams.type) {
								//"type":"BibitemBook"
								const resType = this.btnGroupItems.filter((itm) => {
									return itm.savedParams == savedParams.type;
								});
							}
							if (savedParams.unit && savedParams.unit.ids.length) {
								// "unit":{"ids":[195260597],"values":["Проектная лаборатория по изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв."]}
								this.queryParams.push({
									type: "unit",
									id: savedParams.unit.ids,
									title: savedParams.unit.values,
								});
							}
							if ((savedParams.childDeparts && savedParams.childDeparts.ids.length) || (savedParams.childDepart && savedParams.childDepart.ids.length)) {
								// "unit":{"ids":[195260597],"values":["Проектная лаборатория по изучению творчества Юрия Любимова и режиссерского театра XX-XXI вв."]}
								this.queryParams.push({
									type: "childDeparts",
									id:	savedParams?.childDeparts?.ids || savedParams?.childDepart?.ids,
									title: savedParams?.childDeparts?.values ||	savedParams?.childDepart?.values,
								});
							}
							if ((savedParams.keywords && savedParams.keywords.ids.length) || (savedParams.keyword && savedParams.keyword.ids.length)) {
								//  {"ui": 1, "keyword": {"ids": [144499588], "values": ["философский анализ института президентства"]}}
								this.queryParams.push({
									type: "keyword",
									id: savedParams?.keywords?.ids || savedParams?.keyword?.ids,
									title: savedParams?.keywords?.values ||	savedParams?.keyword?.values,
								});
							}
							if ((savedParams.authors && savedParams.authors.ids.length) || (savedParams.author && savedParams.author.ids.length)) {
								//  {"ui": 1, "sort": {"field": "year", "direction": 1}, "author": {"ids": [35436393], "values": ["Кряжков Владимир Алексеевич"]}, "recursive": "recursive", "doc_access": "public"}}
								this.queryParams.push({
									type: "author",
									id:	savedParams?.authors?.ids || savedParams?.author?.ids,
									title: savedParams?.authors?.values || savedParams?.author?.values,
								});
							}
							if (savedParams.devline && savedParams.devline.ids.length) {
								this.queryParams.push({
									type: "devLine",
									id: savedParams.devline.ids,
									title: savedParams.devline.values,
								});
							}
							if (savedParams.priorityTargets && savedParams.priorityTargets.ids.length) {
								this.queryParams.push({
									type: "devLine",
									id: savedParams.priorityTargets.ids,
									title: savedParams.priorityTargets.values,
								});
							}
							if (savedParams.campus) {
								//  {"ui": 1, "sort": {"field": "year", "direction": 1}, "campus": 22723,
								this.queryParams.push({
									type: "campus",
									id: savedParams.campus,
									title: "",
								});
							}
							if (savedParams.year) {
								//  {"ui": 1, "sort": {"field": "title", "direction": 1}, "type": "BibitemArticle", "year": {"max": 2019, "min": 2013},
								this.queryParams.push({
									type: "year",
									id: savedParams.year,
									title: "",
								});
							}
							if ((savedParams.language && savedParams.language.ids.length) || (savedParams.languages && savedParams.languages.ids.length)) {
								// 	//  {"ui": 1, "sort": {"field": "title", "direction": 1}, "type": "BibitemArticle", "language": {"ids": [20591], "values": ["русский"]},
								//  {"ui": 1, "sort": {"field": "title", "direction": 1}, "type": "BibitemArticle", "languages": {"ids": [20591], "values": ["русский"]},
								this.queryParams.push({
									type: "language",
									id:	savedParams?.language?.ids || savedParams?.languages?.ids,
									title: savedParams?.language?.values ||	savedParams?.languages?.values,
								});
							}
							if (savedParams.research_target && savedParams.research_target.ids.length) {
								//  {"ui": 1, "research_target": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
								this.queryParams.push({
									type: "researchTarget",
									id: savedParams.research_target.ids,
									title: savedParams.research_target.values,
								});
							}
							if (savedParams.researchTargets && savedParams.researchTargets.ids.length) {
								//  {"ui": 1, "researchTargets": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
								this.queryParams.push({
									type: "researchTarget",
									id: savedParams.researchTargets.ids,
									title: savedParams.researchTargets.values,
								});
							}
							if (savedParams.pb && savedParams.pb.ids.length) {
								//  {"ui": 1, "pb": {"ids": [218116772], "values": [""]}}}
								this.queryParams.push({
									type: "pb",
									id: savedParams.pb.ids,
									title: savedParams.pb.values,
								});
							}
							if (savedParams.mg && savedParams.mg.ids.length) {
								//  {"ui": 1, "mg": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
								this.queryParams.push({
									type: "mg",
									id: savedParams.mg.ids,
									title: savedParams.mg.values,
								});
							}
							if (savedParams.sr && savedParams.sr.ids.length) {
								//  {"ui": 1, "sr": {"ids": [218116772], "values": ["Фундаментальная медицина"]}}}
								this.queryParams.push({
									type: "sr",
									id: savedParams.sr.ids,
									title: savedParams.sr.values,
								});
							}
							this.searchAutoRun = Boolean(this.queryParams.length > 0);
						} else {
							return null;
						}
					})
					.catch((err) => {
						console.log(`>>> savedCriteria.err: ${JSON.stringify(err, '',4)}`);
						return null;
					});
			},
		},

		/**
		 * Сервер. Первичное гидрирование
		 */
		serverPrefetch() {
			// this.$store.dispatch("aLoadConfig", {config: this.$ssrContext.config});
			// this.$store.dispatch("aLoadTemplateData", { baseURL: this.config.serverBaseURL });
			// this.mody(this.config.serverBaseURL);
			// return this.searchStart(this.config.serverBaseURL, this.$route.params && this.$route.params.pg && this.$route.params.pg > 0 ? Number(this.$route.params.pg) : 0);
		},
		/**
		 * Клиент. Доп. загрузка
		 */
		async mounted() {
			if(!this.itemList || !this.itemList.length) {
				await this.$store.dispatch("aLoadTemplateRuVersion", { lang: this.config.lang });
				await this.$store.dispatch("aLoadTemplateData", { baseURL: this.config.clientBaseURL });
				if (this.config.widgetName==='PubsMarked' && this.config.pubsUnit && this.config.pubsUnit > 0) 
					await this.$store.dispatch("aUrlById", { baseURL: this.config.clientBaseURL, params: {id: this.config.pubsUnit, withoutDomain: false, lang: this.config.lang === 'ru' ? 'ru' : 'en'} });
				this.config.lang && await this.$store.dispatch("aLoadTemplateRuVersion", { lang: this.config.lang });
				await this.$store.dispatch("aWidgetParamsShow", { baseURL: this.config.clientBaseURL, params: {pubsUnit: this.config.pubsUnit}});
				// console.log(`>>> PubsUnit widgetOperParams: ${JSON.stringify(this.widgetOperParams)}`);
				if (this.config.widgetName==='AuthorSearch') {
					this.showCover = true;
					this.showTotalPubls = false;
				}
				await this.mody(this.config.clientBaseURL);
				await this.searchWidgetStart(this.config.clientBaseURL, this.$route.params && this.$route.params.pg && this.$route.params.pg > 0 ? Number(this.$route.params.pg) : 0);
			}	
		},
	};
</script>

// -Search -
// https://p1.hse.ru/sandbox/multiwidgets.html
//
// http://hsew.local/widgetSearch/142328146
// http://hsew.local/widgetSearch/175490207
// http://hsew.local/widgetSearch/120026365
// http://hsew.local/widgetSearch/120026365?search=650c3696e584b768a48941a856b20778&quick=
// -Marked-
// http://hsew.local/widgetPubsMarked/120026365
// http://hsew.local/widgetPubsMarked/120026365?search=650c3696e584b768a48941a856b20778&quick=
// http://hsew.local/widgetPubsMarked/122897871?search=650c3696e584b768a48941a856b20778&quick=
// 
// https://p1.hse.ru/org/persons/61128#sci
// 61128 - Стерлигова
// 65156 - Кузьминов
// 712217358
// 699306592
// 681484375
// 662158014
// 698306708
// - AuthorSearch - 
// https://p1.hse.ru/staff/AllaSterligova?pubs_widget=1#sci
// https://p1.hse.ru/staff/parshakov?pubs_widget=1#sci
// https://p1.hse.ru/staff/kouzminov?pubs_widget=1#sci
// https://p1.hse.ru/org/persons/65665?pubs_widget=1#sci (Ясин)
// https://p1.hse.ru/org/persons/63968?pubs_widget=1#sci (Радаев)
// https://p1.hse.ru/org/persons/203683?pubs_widget=1#sci (Гохберг)
// https://p1.hse.ru/staff/ddagaev?pubs_widget=1#sci
// https://p1.hse.ru/staff/arjantsev?pubs_widget=1#sci (Аржанцев)
// https://p1.hse.ru/org/persons/25477?pubs_widget=1#sci (Абанкина)
// https://p1.hse.ru/staff/yudkevich?pubs_widget=1#sci (Юдкевич)
// https://p1.hse.ru/staff/dobryakova?pubs_widget=1#sci
// https://p1.hse.ru/org/persons/564063?pubs_widget=1#sci (Коптюбенко)
// https://p1.hse.ru/staff/steiner?pubs_widget=1#sci (Штейнер)

