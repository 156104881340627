<template>
	<div class="header-top header-top--primary">
		<div class="left">
			<!-- +control_sitemap -->
			<span class="control control_sitemap">
				<!--ins></ins-->
				<span class="control_sitemap__line"></span>
			</span>


			<!-- +control_home -->
			<a class="control control_home" :href="getHseURLLang()">
				<ins>
					<svg width="18" height="26" viewBox="0 0 18 26" xmlns="http://www.w3.org/2000/svg">
						<path class="control__path" d="M6.66 0l.134.002h1.336c2.639 0 3.745.464 4.994 1.428 1.819 1.424 2.105 3.492 2.105 4.525 0 1.283-.393 2.638-1.356 3.742-.714.856-1.955 2.097-4.388 2.388-1.663.2-2.891.127-3.506.061v-1.306c.273.038.676.074 1.222.074 1.656 0 2.829-.827 3.543-1.719.81-.983.98-1.909 1.015-2.737h-4.555v-1.282h4.515c-.116-.866-.448-1.528-.985-2.179-1.035-1.283-2.499-1.497-3.462-1.497l-6.505-.002-.768-1.498h6.66zm-6.317 26l.804-1.564v-21.844h3.55v21.82h2.508v-8.523h3.276v8.514h2.838v-3.878c0-2.351-.122-5.622-2.77-7.311l1.542-.566c3.194 1.186 4.791 4.283 4.791 8.362v3.428l.804 1.562h-17.344z" fill="#1658DA"></path>
					</svg>
				</ins>
			</a>
		</div>
		<div class="right">
			<div class="header__controls">
				<!-- +control_campus -->
				<span v-if="!isEmpty(campus)" class="control control_wider control_campus">
					<span clccv="popup_opener link link_dark link_dotted n with-icon with-icon_right with-icon_sortdown">
						<span class="is-desktop">{{t ? t.campus : ''}}&nbsp;</span>
						<ins class="flag flag_msk"></ins>
						<span v-if="origin.campus" class="is-desktop">{{origin.campus.genitive}}</span>
					</span>
					<div class="popup popup_campus-select" style="display: none;">
						<ul class="navigation.smaller">
							<li v-for="item in campus.links" :key="item.id">:
								<a :class="`with-icon with-icon_${item.icon}`" :href="item.url">item.title</a> 
							</li>
						</ul>
					</div>
				</span>
				<!-- +control_lang -->
				<ul v-if="origin && origin.languages && origin.languages.length>1" class="control_lang2">
					<li v-for="lang in origin.languages" :key="lang.language" :class="`control_lang2_item ${lang.selected ? 'activated' : ''}`">
						<a class="link link_no-underline" :href="`${lang.href}${curUrl}`">{{lang.language}}</a> 
					</li>
				</ul>
				<!-- +control_vision -->
				<span class="control control_vision cssClass" :title="`${tt('visionLabel')}`" @click.stop=onOpenBlindPanel>
					<svg width="21" height="12" viewBox="0 0 21 12" xmlns="http://www.w3.org/2000/svg">
						<path class="control__path" d="M10.5 0c3.438 0 6.937 2.016 10.5 6.047-.844.844-1.383 1.375-1.617 1.594-.234.219-.805.703-1.711 1.453-.906.75-1.641 1.266-2.203 1.547-.563.281-1.305.578-2.227.891-.922.313-1.836.469-2.742.469-1.125 0-2.156-.141-3.094-.422-.938-.281-1.875-.766-2.813-1.453-.938-.688-1.672-1.273-2.203-1.758-.531-.484-1.328-1.273-2.391-2.367 2.031-2.031 3.836-3.539 5.414-4.523 1.578-.984 3.273-1.477 5.086-1.477zm0 10.266c1.156 0 2.148-.422 2.977-1.266.828-.844 1.242-1.844 1.242-3s-.414-2.156-1.242-3c-.828-.844-1.82-1.266-2.977-1.266-1.156 0-2.148.422-2.977 1.266-.828.844-1.242 1.844-1.242 3s.414 2.156 1.242 3c.828.844 1.82 1.266 2.977 1.266zm0-5.766c0 .438.141.797.422 1.078s.641.422 1.078.422c.313 0 .625-.109.938-.328v.328c0 .688-.234 1.273-.703 1.758-.469.484-1.047.727-1.734.727-.688 0-1.266-.242-1.734-.727-.469-.484-.703-1.07-.703-1.758s.234-1.273.703-1.758c.469-.484 1.047-.727 1.734-.727h.375c-.25.313-.375.641-.375.984z" fill="#1658DA"></path>
					</svg>
				</span>
				<!-- +control_user -->
				<a class="control control_user cssClass" :href="`${getHseURLLang()}/lkuser/`" :title="`${tt('controlUserLabel')}`">
					<ins>
						<svg class="control_svg" width="17" height="18" viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
							<path class="control__path" d="M13.702 13.175c.827.315 1.486.817 1.978 1.506.492.689.738 1.467.738 2.333h-16.419c0-1.417.532-2.5 1.595-3.248.394-.276 1.358-.591 2.894-.945.945-.118 1.457-.374 1.536-.768.039-.157.059-.61.059-1.358 0-.118-.039-.217-.118-.295-.157-.157-.315-.433-.472-.827-.079-.315-.157-.787-.236-1.417-.157.039-.285-.02-.384-.177-.098-.157-.177-.364-.236-.62l-.089-.443c-.157-.866-.098-1.28.177-1.24-.118-.157-.217-.532-.295-1.122-.118-.866-.059-1.634.177-2.303.276-.748.768-1.319 1.476-1.713.709-.394 1.476-.571 2.303-.532.787.039 1.506.276 2.156.709.65.433 1.093 1.024 1.329 1.772.197.551.217 1.319.059 2.303-.079.472-.157.768-.236.886.118-.039.207 0 .266.118.059.118.079.266.059.443l-.059.472c-.02.138-.049.246-.089.325l-.118.413c-.039.276-.108.472-.207.591-.098.118-.226.157-.384.118-.079.866-.217 1.476-.413 1.831 0 .039-.069.138-.207.295-.138.157-.207.256-.207.295v.65c0 .394.039.689.118.886.079.197.354.354.827.472.276.118.679.217 1.211.295.532.079.935.177 1.211.295z" fill="#1658DA"></path>
						</svg>
					</ins>
				</a>

				<!-- +control_search -->
				<span class="control control_search" :title="`${tt('search')}`" @click.stop="onSeleSearchPanel">
					<ins class="popup_opener">
						<svg xmlns="http://www.w3.org/2000/svg" class="control_svg" width="19" height="19" viewBox="0 0 19 19">
							<path d="M12.927 7.9c0-1.384-.492-2.568-1.476-3.552s-2.168-1.476-3.552-1.476-2.568.492-3.552 1.476-1.476 2.168-1.476 3.552.492 2.568 1.476 3.552 2.168 1.476 3.552 1.476 2.568-.492 3.552-1.476 1.476-2.168 1.476-3.552zm4.053 11.1l-4.603-4.592c-1.339.928-2.832 1.391-4.477 1.391-1.07 0-2.093-.208-3.069-.623-.976-.415-1.818-.976-2.525-1.683-.707-.707-1.268-1.549-1.683-2.525-.415-.976-.623-1.999-.623-3.069 0-1.07.208-2.093.623-3.069.415-.976.976-1.818 1.683-2.525.707-.707 1.549-1.268 2.525-1.683.976-.415 1.999-.623 3.069-.623 1.07 0 2.093.208 3.069.623.976.415 1.818.976 2.525 1.683.707.707 1.268 1.549 1.683 2.525.415.976.623 1.999.623 3.069 0 1.646-.464 3.138-1.391 4.477l4.603 4.603-2.031 2.02z" fill="#1658DA"></path>
						</svg>
					</ins>
					<div v-if="showSearchPanel" class="popup popup_search">
						<div class="popup__inner">
							<div class="search-form">
								<form :action="`${getHseURLLang()}/search/index.html`">
									<div class="search-form__button">
										<button class="button button_grey ">{{tt('search')}}</button>
									</div>
									<div class="search-form__input">
										<input type="text" name="text" :placeholder="`${tt('search')}`" class="input input100 input_mr">
										<input type="hidden" name="simple" value="1">
										<input type="hidden" name="searchid" value="2284688">
									</div>
								</form>
							</div>
							<div class="popup__block popup__block_indent">
								<a :href="`${getHseURLLang()}/search/search.html?simple=0`" class="link link_dark no-visited">{{tt('advancedSearch')}}</a>
								<span class="b-adv-search"></span>
							</div>
						</div>
					</div>
								</span>

				<!-- +control_menu -->
				<span class="control is-mobile control_menu js-control_menu_open" onclick="void(0)">
					<ins></ins>
				</span>
			</div>
		</div>
		<div class="header_body">
			<!-- +breadcrumb_mobile -->
			<div class="header_breadcrumb is-mobile c">
				<div class="header_breadcrumb__inner no_crumb_arrow">
					<a v-if="origin && origin.campus" class="header_breadcrumb__link fa-header_body__crumb--first fa-header_body__crumb" :href="origin.campus.url">
						<template v-if="origin.campus.flag == 'msk' && topHseName && topHseName.short">
							{{topHseName.short}}
						</template>
						<template v-else-if="origin.campus.flag == 'msk'">
							<template v-if="origin.language == 'ru'">| &laquo;</template>
							{{ topHseName ? topHseName.title :""}}
							<template v-if="origin.language == 'ru'">| &raquo;</template>
						</template>
						<template v-else>
							{{origin.campus.title}}
						</template>
					</a>
				</div>

			</div>
			<!-- +breadcrumb_general -->
			<div class="header_breadcrumb is-desktop">
				<div class="header_breadcrumb__inner no_crumb_arrow js-header_breadcrumb__inner--wide">
					<ul v-if="breadCrumbs" class="header_breadcrumb__list">
						<li v-for="(item, index) in breadCrumbs" :key="item.title" class="header_breadcrumb__item">
							<span v-if="breadCrumbs.length - 1 == index" class="header_breadcrumb__current">
								<span>{{item.title}}</span>
							</span>
							<a v-else class="header_breadcrumb__link" :href="item.url">
								<span>{{item.title}}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { mixUtils } from '../mixins/mixUtils.js';
    export default {
		name: 'PublsHeaderTop',
        props: {
            campus: {
                type: Array,
                required: false,
				default: () => [],
			},
			origin: {
				type: Object,
				required: false,
				default: () => {},
			},
			topHseName: {
				type: Object,
				required: false,
				default: () => {},
			},
			ruVersion: {
                type: Boolean,
                required: false,
                default: true
            },
			t: {
                type: Object,
                required: false,
                default: () => {},
			},
			breadCrumbs: {
                type: Array,
                required: true,
			},
			showSearchPanel: {
                type: Boolean,
                required: false,
				default: false,
			},
			config: {
                type: Object,
                required: false,
                default: () => {},
			},
			onOpenBlindPanel: {
				type: Function,
				required: true,
			},
			onSeleSearchPanel: {
				type: Function,
				required: true,
			},
		},
		mixins: [mixUtils],
		data: () => ({
			curUrl: '',
		}),
		methods: {},
        mounted () {
			this.separateUrl();
		},
    };
</script>