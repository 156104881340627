<template>
	<div id="copyright">
		<div class="posts posts--page posts_general">
			<div class="post post_single post_first post_last">
				<div class="post__content">
					<h1 v-html="copyrightRule && copyrightRule.title ? copyrightRule.title : ''" class="post-title"></h1>
					<div v-html="copyrightRule && copyrightRule.text ? copyrightRule.text : '' " class="post__text "></div>
					<div class="ya-share2" data-services="vkontakte,twitter,telegram,whatsapp,viber" data-limit="5" data-lang="<TMPL_var origin.language>"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'Copyright',
		metaInfo: {
			title: 'Книги',
			meta: [
				{ name: 'search', content: '' },
				{ name: 'description', content: '' },
			],
		},
		computed: mapState ({
			copyrightRule: state => state.copyrightRule,
			ruVersion: state => state.templateRuVersion,
			config: state => state.config,
		}),

		serverPrefetch () {
			this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
			return this.loadRule(this.config.serverBaseURL);
		},


		mounted () {
			if (!this.copyrightRule) {
				// console.log(`>>> Copyright.mounted : ${Date.now().toLocaleString('ru-RU')}`)
				this.loadRule(this.config.clientBaseURL);
			}
			this.$store.dispatch('aChangeSelectSecongaryMenu', 2);
		},
		methods: {
			loadRule(baseURL){
				const params = {
					acceptLanguage: this.ruVersion ? 'ru' : 'en'
				};
				return this.$store.dispatch('aLoadCopyrightRuleData', {params, baseURL});
			}

		},

	};
</script>
