<template>
    <div class="sidebar sidebar_normal">
        <div class="sidebar__inner">
            <div class="module card card_yellow card_first">
                <div class="card__inner">
                    <span class="pubs-label">{{tt('priorityAreas')}}</span><br>
                    <ul class="navigation navigation_inline">
                        <li v-for="itm in devLine" :key='itm.id'><a :href="`${getPrefixURLLang()}search/?dl=${itm.id}`" class="link">{{itm.title}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="module card card_yellow">
                <div class="card__inner">
                    <span class="pubs-label">{{tt('byYear')}}</span>
                    <ul class="navigation navigation_inline">
                        <li v-for="(itm, index) in years" :key='index' v-show="showAllYears || index <= 9"><a :href="`${getPrefixURLLang()}search/?year=${itm}`" class="link">{{itm}}</a></li> 
                        <li class="js-year_more"><a href="#" class="pseudo_link" @click='showAllYears = !showAllYears'>{{!showAllYears ? tt('more') : ''}}</a></li>
                    </ul>
                </div>
            </div>

            <div class="module card card_yellow">
                <div class="card__inner">
                    <span class="pubs-label">{{tt('subject')}}</span>
                    <div class="filter_tree">
                        <tree-view
                            v-if="isClientReady"
                            :data="researchTarget"
                            nodeLabelProp="title"
                            nodeChildrenProp="children"
                            prependIconClass="fas"
                            :nodesDraggable = false
                            :hrefPrefix="`${getPrefixURLLang()}rubric/`"
                        ></tree-view>
                            <!-- @nodeSelect="nodeSelect" -->
                    </div>
                </div>
            </div>
	<!--/noindex-->
            <div class="module card card_white">
                <div class="card__inner">
                    <div class="card__head">
                        <div class="g-title h5">
                            <a :href="`https://publications.hse.ru${!this.ruVersion ? '/en/' : '/'}news/`" class="link link_dark">{{tt('news')}}</a>
                        </div>
                    </div>
                    <div class="events">
                        <div v-for="itm in publNews" :key="itm.id" class="events__item">
                            <div class="events__date">{{itm.publ_date && Number(itm.publ_date) ? dateFormatter( Number(itm.publ_date), ruVersion) : ''}}</div>
                            <div class="events__title">
                                <a :href="itm.href" class="link link_dark">{{itm.title}}</a>
                            </div>
                            <div v-html="itm.annotation" class="events__annotation">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="b-side">
                <div class="b-side__inner">
                </div>
            </div>
            <div v-html="tt('mistakeText')"></div>
        </div>
	</div>
</template>

<script>
    import Vue from 'vue';
    import { mixUtils } from '../mixins/mixUtils.js';
    export default {
        name: 'PublsSidebar',
        props: {
            devLine: {
                type: Array,
                required: true,
                default: []
            },
            years: {
                type: Array,
                required: true,
                default: []
            },
            researchTarget: {
                type: Array,
                required: true,
                default: []
            },
            publNews: {
                type: Array,
                required: true,
                default: []
            },
            t: {
				type: Object,
				required: false,
				default: () => {},
			},
            ruVersion: {
                type: Boolean,
                reuired: true,
            },
			config: {
                type: Object,
                required: false,
                default: () => {},
			},
        },
        mixins: [mixUtils],
        data: () => ({
            showAllYears: false,
            isClientReady: false,
        }),
        mounted () {
            if (typeof window !==  'undefined') {
                Vue.component('tree-view', () => import('./ssrTreeView/treeView.vue'));
                this.isClientReady = true;
			}
        },
    };
</script>